.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    margin-top: 3.2rem;
}

input::-webkit-inner-spin-button {
    margin: 0;
}

.paddedContainer {
    width: 100%;
}

.imageWrapper {
    position: relative;
    ;
    width: 100%;
    background-color: white;
}

.bannerHeading {
    position: absolute;
    top: 30%;
    left: 16%;
    font-size: 2.25rem;
    font-weight: var(--font-weight-bold);
    color: var(--heading-dark-color);
}

.bestSellingBooksMain {
    background-color: rgb(274, 244, 249);
}

.bestSellingBookHeading {
    height: 4.10rem;
    background-color: rgb(225, 214, 231);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bestSellingBookHeading p {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    color: var(--app-dark);
}

.bestSellingBookContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    gap: 0.5rem;
}

.selectedBook {
    flex: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mostPopularBooksMain {
    background-color: rgb(254, 248, 243);
}

.bestSellingBooksWrapper {
    flex: 0.4;
    display: grid;
    gap: 1.5rem;
}

.mostPopularBookHeading {
    height: 4.10rem;
    background-color: rgb(252, 220, 198);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mostPopularBookHeading p {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    color: rgb(242, 110, 20)
}

.mostPopularBooks {
    display: flex;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 4rem 4.5rem;
}

.mostPopularBookWrapper {
    display: flex;
}

.mostPopularBookNumber {
    font-size: 7.5rem;
    font-weight: var(--font-weight-bold);
    color: rgba(0, 0, 0, 1);
    opacity: 9%;
    margin-right: -1.1rem;
}

.innerContainer {
    background-color: white;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
}

.hidden {
    display: none;
}

.banner {
    width: 100%;
}

.search {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
}

.categories {
    margin-top: 3.25rem;
}

.bookList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
}


.pagination {
    display: flex;
    gap: 0.5rem;
}

.pageItem {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid #E8E9EC;
    border-radius: 4px;
    text-align: center;
    color: #4D4F5C;
    display: flex;
    align-items: center;
    justify-content: center;
}

.break {
    width: 2em;
    height: 2rem;
    padding: 0.5rem;
    display: flex;
    align-items: end;
    justify-content: center;
}

.pageItemPrevious {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid #E8E9EC;
    border-radius: 4px;
    text-align: center;
    color: #E8B03E;
    display: flex;
    align-items: center;
    justify-content: center;
}


.pageItemActive {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid var(--button-color-orange);
    border-radius: 4px;
    text-align: center;
    color: white;
    display: flex;
    background: var(--button-color-orange);
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.pageLink {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageLink[aria-disabled="true"] {
    pointer-events: none;
    opacity: 0.4;
}

.searchBoxWrapper {
    border-bottom: 1px solid black;
}

@media screen and (min-width: 820px) and (max-width: 1200px) {
    .bannerHeading {
        top: 20%;
    }

    .selectedBook {
        flex: 0.5;
    }

}

@media screen and (min-width: 760px) and (max-width: 1000px) {
    .bannerHeading {
        top: 20%;
    }

    .selectedBook {
        flex: 0.5;
    }

    .bestSellingBookContainer {
        gap: 1rem;
        justify-content: space-between;
        padding: 5rem;
    }
}

@media screen and (min-width: 480px) and (max-width: 760px) {
    .bannerHeading {
        top: 15%;
        font-size: 1.75rem;
        left: 6%;
        margin: 0;
    }

    .selectedBook {
        flex: 0.5;
    }

    .mostPopularBookWrapper {
        width: 100%;
        justify-content: flex-end;
        padding-right: 5rem;
    }

    .search {
        width: 100%;
        justify-content: space-around;
    }
}

@media (max-width: 480px) {
    .banner {
        height: 10rem;
    }

    .bannerHeading {
        top: 25%;
        left: 50%;
        transform: translate(-50%, -8px);
    }

    .selectedBook {
        flex: 0.5;
    }

    .mostPopularBookWrapper {
        width: 80%;
        justify-content: flex-end;
    }

    .search {
        width: 100%;
        justify-content: space-around;
    }

    .bestSellingBookContainer {
        flex-direction: column;
        row-gap: 1rem;
    }

    .bestSellingBooksWrapper {
        flex: 0;
        gap: 1.5rem;
        width: 100%;
    }
}