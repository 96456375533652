.workShopForm{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


.input {
    width: 100%;
    background-color: var(--input-background-color);
    color: black;
    border: none;
    border-radius: 25px;
    padding-left: 1rem;
    height: 2.25rem;
    width: 100%;
    box-sizing: border-box;
}

.input::placeholder{
    color: black;
}

.input:focus {
    outline: none;
}

.errorMsg{
    color: red;
}

.formInputFields{
    display: flex;
    align-items: center;
}

.formInputFields img{
    height: 1.5rem;
}