.section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.section button {
    width: 45%;
    margin: 5px;
}

.quote {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 0.5rem;
    margin: 0.5rem 0;
    font-size: 0.875rem;
    cursor: pointer;
}

.word {
    padding: 0.375rem;
    border-radius: 0.25rem;
    border: 1px solid lightgray;
    margin: 0.375rem 0;
    cursor: pointer;
    font-size: 0.875rem;
}

.word:hover,
.quote:hover {
    background-color: lightgray;
}

.wordListContainer,
.quotesContainer {
    margin-top: 0.75rem;
    overflow: auto;
    height: 90%;
    min-height: 20rem;
}

.innerMain {
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
    overflow: auto;
    height: 73%;
    flex: 1;
}

.actionContainer {
    display: flex;
    column-gap: 0.25rem;
}

.primaryBtn {
    width: 6rem;
    height: 2rem;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 0.25rem;
}

.activeButton {
    background-color: var(--button-color-yellow);
    color: white;
}

.activeButton:hover {
    background-color: var(--button-color-yellow) !important;
    color: white;
}

.closeIcon {
    width: 1.5rem;
    cursor: pointer;
}

.titleContainer {
    display: flex;
    gap: 0.5rem;
}

.titleContainer>div {
    display: flex;
    column-gap: 0.25rem;
}

.titleContainer>div>img {
    width: 1.25rem;
    cursor: pointer;
}

.activeTabContainer {
    padding: 1.25rem 0.125rem;
}

.tabName {
    font-weight: 500;
}

.sentence {
    background-color: var(--panel-dark-background);
    padding: 0.625rem 0.875rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    cursor: pointer;
}

.sentenceContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.875rem;
}

.instruction {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0.5rem 0;
}

.nameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.filterButton {
    height: 1.5rem;
    font-weight: 400;
    font-size: 0.875rem;
}

.filterContainer {
    padding: 0.5rem;
    background-color: var(--panel-dark-background);
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.filterContainer>div {
    font-size: 0.875rem;
}

.searchButton {
    height: 2rem;
    width: 100%;
}

.user {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.001rem;
}

.aiHelpQuestion {
    margin-top: 0.5rem;
    color: #000;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.875rem;
    letter-spacing: -0.0015rem;
}

.selectThemeFormGroup {
    margin-top: 1.25rem;
}

.selectThemeFormGroup input {
    border-radius: 0.5rem;
    background: #FAFAFA;
}

.selectThemeLabel {
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: -0.00075rem;
}

.formControl {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.formControl span {
    flex: 1;
    width: max-content;
    text-wrap: nowrap;
    word-wrap: normal;
}

.subSection {
    padding: 0.375rem 1rem 0.375rem 0.375rem;
    width: calc(50% - 2rem);
    display: flex;
    gap: 0.5rem;
    min-width: 5rem;
    align-items: center;
    border-radius: 3.625rem;
    cursor: pointer;
}

.subSectionLabel {
    color: #7B7B7B;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5625rem;
    letter-spacing: -0.00088rem;
    margin-top: 2rem;
}

.subSectionContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1.25rem;
}

.subSectionLogo {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    width: 2rem;
    height: 2rem;
}

.subSectionTitle {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: -0.00088rem;
}

.moveToStoryline {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.storyLineLabel {
    color: #000;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.1875rem;
    letter-spacing: -0.00088rem;
}

.viewStorylineBtn {
    margin-top: 0.875rem;
}

.container {
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
}