.main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem 0 5rem 0;
    margin-top: 3.2rem;
    background-color: var(--background-color);
}

.container {
    width: 85%;
    box-sizing: border-box;
    height: 100%;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3.125rem 6.25rem;
    background: white;
    border-radius: 8px;
}

.blogListingContainer {
    padding: 3.125rem 4.5rem;
}

.blogHeading {
    width: 100%;
    display: grid;
    gap: 1.5rem;
}

.blogtitle {
    margin: 0;
}

.blogdescription {
    /* font: normal normal 400 .875rem/1.5 'Open Sans', sans-serif; */
    font-size: .875rem;
    font-family: 'Open Sans', sans-serif;
}


.heading {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    color: var(--heading-dark-color);
    margin: 0;
}

.headerMenu {
    display: flex;
    gap: 3rem;
    margin: 0;
    width: 100%;
    border-bottom: 2px solid #cfc7c7;
}

.menuItems {
    font-size: 0.875rem;
    color: #707070;
    cursor: pointer;
    position: relative;
    font-weight: var(--font-weight-medium);
    bottom: -2px;
    display: inline-block;
    padding: 0.4rem 0;
}

.active {
    color: black;
    border-bottom: 2px solid var(--selection-dark);
}

.backButtonWrapper {
    width: 85%;
}

.contentWrapper {
    width: 100%;
    height: 100%;
}

.blogContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
}

.blogContentContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    user-select: text;
    max-width: 844px;
    margin: auto;
    width: 100%;

}

.blogContent {
    padding: 1rem;
}

.blogContent img {
    max-width: 100%;
    height: unset;
}

.blogBannerImageContainer img {
    width: 100%;
    height: 100%;
    border-radius: 10px;

}

.shareIconContainer {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: relative;
}


.iconBar {
    position: sticky;
    top: 30%;
    background: rgb(28, 16, 92);
}

.iconFixed {
    position: fixed;

}

.shareIcon {
    display: none;
    position: absolute;

    top: 50%;
    height: 140%;
    left: 5px;
}


.iconBar a {
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease;
    color: white;
    /* font-size: 20px; */
}

.iconBar a:hover {
    background-color: #000;
}

.shareIconsBottom {
    display: flex;
    gap: 0.5rem;
}

.shareIconsBottom a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    transition: all 0.3s ease;
    color: white;
}

.shareIconsBottom a:hover {
    background-color: #000;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 2rem;
}

.pageItem {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid #E8E9EC;
    border-radius: 4px;
    text-align: center;
    color: #4D4F5C;
    display: flex;
    align-items: center;
    justify-content: center;
}

.break {
    width: 2em;
    height: 2rem;
    padding: 0.5rem;
    display: flex;
    align-items: end;
    justify-content: center;
}

.pageItemPrevious {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid #E8E9EC;
    border-radius: 4px;
    text-align: center;
    color: #E8B03E;
    display: flex;
    align-items: center;
    justify-content: center;
}


.pageItemActive {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid var(--button-color-orange);
    border-radius: 4px;
    text-align: center;
    color: white;
    display: flex;
    background: var(--button-color-orange);
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.pageLink {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageLink[aria-disabled="true"] {
    pointer-events: none;
    opacity: 0.4;
}


@media screen and (max-width: 360px) {
    .container {
        width: 90%;
        padding: 1.125rem 1.25rem;
    }

}

@media screen and (max-width: 440px) {
    .blogContainer {
        justify-content: center;
    }
}

@media screen and (min-width: 481px) and (max-width: 820px) {
    .container {
        padding: 2.25rem;
    }

    .blogListingContainer {
        padding: 1.25rem;
    }

    .iconBar {
        display: none;
    }

    .shareIconContainer {
        display: flex;
    }
}

@media screen and (min-width: 360px) and (max-width: 480px) {
    .main {
        padding: 1rem 0 5rem 0;
        gap: 1rem;
    }

    .shareIcon {
        display: none;
    }

    .container {
        padding: 1.125rem 2.25rem;
    }

    .shareIconContainer {
        display: flex;
    }

    .iconBar {
        display: none;
    }

    .shareIconsBottom a {
        padding: 6px;
    }

    .shareIconsBottom a img {
        width: 1.5rem;
    }

}

@media screen and (min-width: 820px) {
    .blogBannerImageContainer img {
        width: 100%;
        /* height: 356px; */
        height: 444px;


    }

    .shareIcon {
        display: block;
    }

}

@media screen and (min-width: 440px) and (max-width: 600px) {
    .blogContainer>div {
        width: 12.5rem;
        height: 15.5rem;

    }
}