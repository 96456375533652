.searchBar {
	border-radius: 0;
	border-bottom: 1px solid var(--selection-dark);
}

:global(.infinite-scroll-component__outerdiv) {
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: 1rem;
	height: 100%;
}

.taggedImage {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	object-fit: contain;
	cursor: pointer;
	border: 2px solid transparent;
	outline: 2px solid transparent;
}

.taggedImage:hover {
	outline: 2px solid var(--selection-dark);
	border-radius: 4px;
}

.endMessage {
	grid-column: 1 / -1;
	text-align: center;
	font-size: 0.875rem;
}