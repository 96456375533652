.main {
    display: flex;
    justify-content: space-between;
}

.headingAndDescriptionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.heading {
    font-size: 2.375rem;
    font-weight: var(--font-weight-bold);
    margin: 0;
    color: var(--app-dark)
}

.description {
    font-size: 1rem;
}

.lottie {
    width: 14rem;
    height: 14rem;
}

@media screen and (max-width: 480px) {
    .main {
        display: block;
    }

    .heading {
        font-size: 2rem;
    }

    .lottie {
        width: 8rem;
        height: 8rem;
    }
}