.emptyOrderContainer {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--selection-dark);
    border-radius: 8px;
    height: 30rem;
    padding: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-direction: column;
}

.descriptionTextAndButtonWrapper {
    display: grid;
    gap: 1rem;
    justify-items: center;
}

.descriptionText {
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
}

.action {
    width: fit-content;
    align-self: center;
}

.popup {
    font-size: 0.75rem;
    color: orange;
    padding: 0.5rem;
    text-align: center;
}

@media screen and (min-width: 360px) and (max-width: 600px) {
    .action {
        font-size: 0.75rem;
        padding: 0rem 1rem;
    }

    .descriptionText {
        text-align: center;
    }

}