.main {
  display: grid;
  gap: 2rem;
}

.invoiceContainer {
  position: relative;
  background-color: #fff;
  border-radius: 1rem;
  padding-top: 4rem;
  padding-inline: 1rem;
  z-index: 2;
}

.invoiceContainer:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 2rem; /* The thickness of the border */
  background: linear-gradient(to right, #9028df 0%, #f3189e 100%);
  border-radius: .5rem;
}

.invoiceContainer:after {
  content: '';
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  height: 3rem;
  border-radius: 1rem;
  z-index: 2;
  background-color: #fff;
}


.invoiceHeader {
  display: grid; /* grid */
  gap: 1.5rem; /* gap-6 */
  grid-template-columns: 1fr;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.section * {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.section > .item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.section .primary {
  color: var(--selection-dark);
}

.section .muted {
  color: var(--panel-highlight-color);
}

.invoiceItems {
  margin-top: 1.5rem;
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.tableHeadRow {
  border-bottom: 1px solid #ddd;
}

.tableHeadCell {
  font-size: 0.875rem;
  text-align: left;
  padding-bottom: 0.5rem;
  font-weight: 500;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.tableBodyRow {
  border-bottom: 1px solid #ddd;
}

.tableBodyCell {
  padding: 1rem 0;
  font-size: 0.875rem;
}

.itemDetails {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.itemName {
  font-weight: 500;
}

.itemDescription {
  color: #6c757d;
}

.totalAmountWrapper {
  display: grid;
  gap: .15rem
}

.discountCell {
  font-size: .75rem;
  font-style: italic;
  color: var(--panel-highlight-color);
}

.finalItemAmount {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.paymentDetails {
  margin-top: 1.5rem; /* mt-6 */
  display: grid; /* grid */
  gap: 1rem; /* gap-4 */
}

.paymentMethod {
  font-size: 0.875rem; /* text-sm */
}

.paymentMethodTitle {
  font-weight: 500; /* font-medium */
}

.paymentMethodText {
  color: var(--panel-highlight-color); /* text-muted-foreground */
}

.paymentBreakdown {
  display: flex;
  justify-content: space-between; /* flex justify-between */
  font-size: 0.875rem; /* text-sm */
}

.totalBreakdown {
  display: flex;
  justify-content: space-between; /* flex justify-between */
  border-top: 1px solid #ddd; /* border-t */
  padding-top: 0.5rem; /* pt-2 */
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
}

.totalAmount {
  color: var(--selection-dark); /* text-primary */
}

.printBody {
  padding: 1rem;
}

.invoiceFooter {
  margin-top: 2rem; /* mt-8 */
  text-align: center; /* text-center */
  background: linear-gradient(to right, #9028df 0%, #f3189e 100%);
  padding: 1.5rem;
  border-end-end-radius: 1rem;
  border-bottom-left-radius: 1rem;
  position: relative;
  left: -1rem;
  width: calc(100% - 1rem);
}

.socialLinks {
  display: flex;
  justify-content: center; /* flex justify-center */
  gap: 1rem; /* gap-4 */
}

.contactInfo {
  margin-top: 1rem;
  font-size: 0.875rem; /* text-sm */
  color: #fff; /* text-muted-foreground */
}

.contactInfo a {
  color: #fff;
}

.storeLinks {
  display: flex;
  justify-content: center; /* flex justify-center */
  gap: 1rem; /* gap-4 */
  margin-top: 1rem; /* Ensure proper spacing */
}

.storeImage {
  height: 2rem; /* h-8 */
  width: auto; /* w-auto */
}

.orderConfirmation {
  min-height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
}


.container {
  max-width: 400px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.brand {
  font-size: 24px;
  font-style: italic;
  color: #333;
}

.brand > img {
  width: 10rem;
}

.illustration {
  /* height: 150px; */
  position: relative;
}

.calculator {
  width: unset;
  /* height: 100%; */
}

.title {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1.5rem;
}

@media (max-width: 480px) {
  .container {
    padding: 20px 15px;
  }
  
  .title {
    font-size: 24px;
  }
  
  .illustration {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 768px) {
  .invoiceHeader {
      grid-template-columns: repeat(2, minmax(0, 1fr)); 
  }

  .paymentDetails {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}