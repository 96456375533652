.navBar {
    display: flex;
    flex-direction: row;
    font-family: 'Poppins';
    font-size: 0.875rem;
    gap: 1.2rem;
    color: white;
    font-weight: 700;
}

@media screen  and  (min-width: 820px){
    .navBar{
        gap: 2.5rem;
    }
}