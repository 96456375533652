.secondaryBtn {
    height: 3rem;
    border-radius: 1.75rem;
    border: 2px solid var(--button-color-yellow);
    padding: 0 1.5rem 0 1.5rem;
    background-color: #fff;
    color: var(--button-color-yellow);
    font-family: Poppins;
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
    min-width: 5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.secondaryBtn:hover:not([disabled]) {
    background-color: #fff6e8;
}

.secondaryBtn:focus {
    outline: 0;
    box-shadow: none;
}

.secondaryBtn:disabled {
    opacity: 0.3;
    background-color: white;
    pointer-events: none;
}
