.main {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 17.5rem;
    height: fit-content;
    margin: 0 1rem;
}

.main>div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
}

.main>div:first-child {
    height: calc(100% - 3.5rem);
}

.main>div:last-child {
    height: 3.5rem;
}

.authorImage {
    height: 100%;
    width: 100%;
    z-index: 10;
    padding: .5rem;
    display: flex;
    justify-content: center;
}

.authorImage>img {
    width: 100%;
    object-fit: cover;
}

.authorName {
    font-size: 1.2rem;
    font-weight: var(--font-weight-semiBold);
    color: white;
    text-transform: uppercase;
}

.triangle {
    position: absolute;
    width: 18rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.triangle>img {
    width: 100%;
    resize: both;
    margin-bottom: -2rem;
}

@media screen and (min-width: 481px) and (max-width: 890px) {
    .main {
        /* width: 35%; */
        display: flex;
        justify-content: center;
    }

    .triangle>img {
        width: 80%;
    }

    .triangle {
        position: absolute;
    }

    .authorImage {
        margin-top: 0rem;

    }

    .authorName {
        font-size: 80%;
    }
}

@media screen and (min-width: 360px) and (max-width: 480px) {
    .main {
        /* width: 48%; */
        display: flex;
        justify-content: center;
    }

    .triangle>img {
        width: 65%;

    }

    .triangle {
        position: absolute;
    }

    .authorImage>img {
        /* margin-top: 4rem;
        width: 5rem;
        height: 5rem; */
        /* border-radius: 50%; */
    }

    .authorImage {
        margin-top: 0rem;
    }

    .authorName {
        /* font-size: 80%; */
    }

}