.main {
    display: grid;
    gap: 2.5rem;
}

.container {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--selection-dark);
    border-radius: 8px;
    height: 30rem;
    padding: 2.5rem 5rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.userDetailsWrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1.5rem;
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 2rem
}

.userImage {
    width: 4.25rem;
    height: 4.25rem;
    border-radius: 50%;
    border: 10px solid #F3F3F3;
}

.userName {
    width: fit-content;
    margin: 0;
    font-size: 1rem;
    font-weight: var(--font-weight-bold);
}

.linkButtonWrapper {
    display: flex;
    gap: 2rem;
}

.userNameWrapper {
    display: grid;
    gap: 0.5rem;
}

.labelAndInputWrapper {
    display: grid;
}

.userDetailsLabel {
    font-size: 0.875rem;
}

.inputFelid {
    height: 2rem;
    padding-left: 1.25rem;
    font-size: 0.875;
    font-family: 'Poppins';
    background: #FAFAFA;
    border: 1px solid #F1F0F0;
    border-radius: 4px;
    outline: none;
}

.inputFelid:disabled {
    cursor: not-allowed;
}

.inputFelid:focus {
    border: 1px solid #FB7E05;
    background: #FFF3DF;
}

.disabledInputFelid {
    height: 2rem;
    font-size: 0.875rem;
    user-select: text;
    font-family: 'Poppins';
    background: white;
    border: none;
    border-radius: 4px;
    outline: none;
}

.actions {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 1rem;
}

.authorCode {
    display: flex;
    align-items: center;
}

.copyLinkBtn {
    background-color: transparent;
    border: none;
    outline: none;
    width: 2.5rem;
    height: 2.5rem;
    color: var(--button-color-yellow);
}

.copyLinkBtn:active {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
} 

.copyLinkBtn img {
    width: 100%;
    height: 100%;
}

.copied {
    align-self: center;
    display: flex;
    align-items: center;
    width: 2.5rem;
    text-align: center;
    height: 2.5rem;
    color: var(--button-color-yellow);
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
}

@media screen and (min-width: 360px) and (max-width: 760px) {
    .userDetailsWrapper {
        grid-template-columns: repeat(1, 1fr);
    }

    .container {
        height: auto;
        width: 100%;
        padding: 2.5rem 1rem;
    }

    .action {
        font-size: 0.75rem;
        padding: 0rem 1rem;
    }

    .inputFelid {
        width: 90%;
    }

    .disabledInputFelid {
        width: 100%;
    }
}

@media screen and (min-width: 760px) and (max-width: 900px) {
    .container {
        height: auto;
        width: 100%;
        padding: 2.5rem 1rem;
    }
}