.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    background: var(--background-color);
    margin-top: 3.2rem;
}

.backTOBookstoreWrapper {
    width: 100%;
    box-sizing: border-box;
    height: 5rem;
    background: rgb(208, 194, 219);
    padding-left: 7.5%;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}


.heading {
    height: 1.5rem;
    display: flex;
    font-size: 1rem;
    color: #6A3089;
}

.heading p {
    height: 100%;
    cursor: pointer;
    margin-right: 0.5rem;
}

.heading p:hover {
    margin-right: 0.5rem;
    border-bottom: 1px solid #6A3089;
}

.paddedContainer {
    width: 85%;
}

.box {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    border-radius: 8px;
    justify-content: center;
    box-shadow: 0px 3px 6px #0000001A;
}

.productDetails {
    font-size: 1.25rem;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    width: 100%;
}


.productPictures {
    flex: 0.3;
    padding: 1.5rem 0;
}

.productText {
    flex: 0.7;
}

.productTitle {
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 1.875rem;
    font-weight: var(--font-weight-semiBold);
    color: var(--heading-dark-color);
    text-transform: capitalize;
}

.productAuthor {
    /* margin-top: 0.25rem;
    margin-bottom: 0.25rem; */
    font-size: 1rem;
    color: #014094;
}

.productPrice {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: rgb(242, 110, 20);
    font-weight: var(--font-weight-bold);
}

.deliverydisclaimer {
    font-size: .875rem;
    font-style: italic;
    color: #014094;
}

.aboutProduct {
    font-size: 1rem;
    margin-top: 2rem;
}

.productSummary {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-family: Poppins;
}

.ratingsLikesViews {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.ratingsLikesViewsTitles {
    font-size: 1rem;
    text-align: center;
    opacity: 0.8;
}

.ratingsLikesViewsContent {
    display: flex;
    text-align: center;
}

.starImage {
    margin-left: 0.5rem;
    color: rgb(232, 176, 62);
}

.heartImage {
    color: red;
    margin-left: 0.5rem;
}

.eyeImage {
    color: var(--heading-dark-color);
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
}

.shareButton {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.shareImage {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    border: 1.5px solid rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;
}

.shareTitle {
    opacity: 0.8;
    cursor: pointer;
    font-size: 1rem;
}

.productAddedMessage {
    margin-left: 1rem;
    font-size: 1rem;
}

.giveMeLikeContainer {
    display: flex;
    align-items: center;
    margin-top: 2.25rem;
}

.likeImageWrapper {
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: flex;
    object-fit: contain;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}

.authorProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
}

.authorDetails {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 1rem;
}

.aboutAuthorText {
    font-size: 0.875rem;
    font-family: Poppins;
    margin-bottom: 3rem;
}

.productDetailsBox {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.reviewTitleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.reviews {
    display: flex;
    flex-direction: column;
    padding: 4.5rem 6.5rem;
    width: 100%;
}

.userReviews {
    flex: 0.3;
    font-size: 1.875rem;
    font-weight: var(--font-weight-bold);
    color: var(--heading-dark-color)
}

.xOfyStars {
    font-family: 'Poppins';
    font-size: 1.125rem;
    margin-left: 1.5rem;
}

.topReviews {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    font-size: 1.125rem;
    font-weight: var(--font-weight-regular);
}

.topReviewsWrapper {
    display: contents;
}

.separator {
    border: 1px solid #101010;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.buttonImage {
    margin-left: 0.5rem;
}

.viewMore {
    margin-top: 1.5rem;
    align-self: center;
}

.similarBooks {
    padding: 4.125rem 6.75rem;
}

.similarBooksHeader {
    font-size: 1.875rem;
    font-weight: var(--font-weight-bold);
    color: var(--heading-dark-color);
    display: inline-block;
}

.bookList {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
}

.eula {
    display: flex;
    justify-items: center;
}

.eulaContainer {
    height: 75vh;
    width: 75vw;
}

.modelDialog :global(.MuiDialog-paperWidthSm) {
    max-width: 90vw;
}

.eulaContainer>div {
    height: 100%;
    width: 100%;
}

@media (orientation: portrait) {
    div.eulaContainer {
        padding: 0;
    }

    .modelDialog :global(.MuiDialog-paperWidthSm) {
        transform: rotate(90deg);
        max-width: 100vh;
        min-width: 75vh;
        width: 76rem;
        height: 90vw;
    }

    .eulaContainer {
        width: 100%;
    }
}

.eulaContainer iframe {
    background: white;
    height: 100%;
    width: 100%;
}

.pageNumber {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.pageAdder {
    display: flex;
    justify-content: center;
    border: 2px black dashed;
    height: 7rem;
    align-items: center;
    margin: 0.75rem;
    cursor: pointer;
}

.toggledDropDown {
    position: absolute;
    background-color: white;
    top: 4rem;
    right: 4rem;
    height: fit-content;
    padding: .5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: grid;
    box-shadow: 0px 3px 6px #00000029;
}

.socialMediaIcon {
    width: 1.5rem;
}

.toggledDropDown > .iconLine:not(:last-child) {
    border-bottom: 1px solid #cfcfcf;
}

.iconLine {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    padding: 0.45rem;
    font-weight: var(--font-weight-regular);
}

.link {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.link:hover {
    border-bottom: 1px solid black;
}


.pagination {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin-top: 5rem;
}

.pageItem {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid #E8E9EC;
    border-radius: 4px;
    text-align: center;
    color: #4D4F5C;
    display: flex;
    align-items: center;
    justify-content: center;
}

.break {
    width: 2em;
    height: 2rem;
    padding: 0.5rem;
    display: flex;
    align-items: end;
    justify-content: center;
}

.pageItemPrevious {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid #E8E9EC;
    border-radius: 4px;
    text-align: center;
    color: #E8B03E;
    display: flex;
    align-items: center;
    justify-content: center;
}


.pageItemActive {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid var(--button-color-orange);
    border-radius: 4px;
    text-align: center;
    color: white;
    display: flex;
    background: var(--button-color-orange);
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.pageLink {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageLink[aria-disabled="true"] {
    pointer-events: none;
    opacity: 0.4;
}

.description {
    margin: 1rem 0;
    font-size: 0.875rem;
    width: 80%;
    color: #014094;
}

@media screen and (min-width: 820px) and (max-width: 1200px) {
    .productDetails {
        gap: 2rem;
    }

    .userReviews {
        font-size: 1.5rem;
    }

    .userReviews {
        flex: 0.375;
    }

}

@media screen and (min-width: 480px) and (max-width: 820px) {
    .productDetails {
        gap: 2rem;
    }

    .userReviews {
        font-size: 1.5rem;
    }

    .userReviews {
        flex: 0.5;
    }

    .actions {
        display: flex;
        align-items: center;
    }

    .actionBtn {
        font-size: 0.75rem;
        width: 100%;
        padding: 0.625rem 0.5rem;
        height: none;
        font-weight: bold;
    }

    .giveMeLikeContainer {
        margin-top: 0.25rem;
    }

    .likeTitle {
        font-size: 1.25rem;
    }

    .likeImageWrapper {
        width: 2rem;
        height: 2rem;
        margin-top: 0.5rem;
    }

    .productPictures {
        padding: 0.5rem 0;
    }

    .reviews {
        padding: 1rem 1rem;
    }

    .topReviews {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .similarBooks {
        padding: 0.125rem 1.25rem;
    }

}

@media (max-width: 480px) {
    .productDetails {
        flex-direction: column;
    }

    .userReviews {
        font-size: 1.5rem;
    }

    .userReviews {
        flex: 1;
        font-size: 1.25rem;
    }

    .productAuthor {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }

    /* .productPrice {
        margin-top: 0rem;
    } */

    .ratingsLikesViews {
        margin-top: 0rem;
        gap: 1rem;
    }

    .ratingsLikesViews>div>div {
        display: flex;
    }

    .productSummary {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }

    .giveMeLikeContainer {
        margin-top: 0.75rem;
    }

    .likeImageWrapper {
        width: 2rem;
        height: 2rem;
        margin-top: 0.5rem;
    }

    .productTitle {
        margin-top: 0rem;
        font-size: 1.5rem;
    }

    .shareButton {
        margin-bottom: -3rem;
    }

    .productPictures {
        padding: 0.5rem 0;
    }

    .reviews {
        padding: 1rem 1rem;
    }

    .topReviews {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .similarBooks {
        padding: 0.25rem;
    }

    .actions {
        margin-top: 0.875rem;
    }

    .toggledDropDown {
        background-color: white;
        left: 70%;
    }

    .paddedContainer {
        width: 95%;
    }

    .bookList {
        display: flex;
        justify-content: center;
        gap: 1.25rem;
    }

    .similarBooksHeader {
        display: flex;
        justify-content: center;
    }

    .eulaContainer {
        height: 25vh;
    }
}