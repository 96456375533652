.main {
    width: 100%;
    border-radius: 8px;
    padding: 1.5rem;
    box-sizing: border-box;
}

.closeButtonWrapper {
    display: flex;
    justify-content: flex-end;
}

.notificationCardWrapper {
    display: flex;
    gap: 1rem;
}

.notificationTitle {
    font-size: 0.875rem;
    font-weight: var(--font-weight-bold);
    margin: 0;
}

.notificationDescription {
    font-size: 0.875rem;
}

.timeZone {
    font-size: 0.875rem;
    opacity: 0.8;
}

.NotificationWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;;
    width: 90%;
}