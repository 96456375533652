.card {
    --feature: #fff;
    aspect-ratio: 413 / 553;
    position: relative;
    perspective: 30000px;
    box-sizing: border-box;
    cursor: pointer;
}

.card * {
    box-sizing: border-box;
}

.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.cardInner.isFlipped {
    transform: rotateY(180deg);
}

.cardFront,
.cardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    padding: 1rem;
    background: linear-gradient(180deg,
            rgb(122, 25, 196),
            rgb(70, 14, 112),
            rgb(67, 14, 107));
}

.cardContent {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;
    border: 1px solid var(--selection-dark);
}

.cardPersonDetails {
    --position: 5%;
    position: absolute;
    width: max-content;
    height: max-content;
    padding: 0.875rem;
    bottom: var(--position);
    left: var(--position);
    border-radius: 0.5rem;
    background: rgba(67, 14, 107, 0.9);
    color: #fff;
}

.cardPersonDetails p {
    font-style: italic;
}

.userImage {
    height: 100%;
    object-fit: contain;
}

.cardBack {
    transform: rotateY(180deg);
}

.cardBack .cardContent {
    display: flex;
    flex-direction: column;
    border: unset;
}

.profileCard {
    background: rgb(58, 2, 101);
    border: 1px solid var(--selection-dark);
    height: 40%;
    block-size: 40%;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
}

.profileCard img {
    height: 100%;
    align-self: flex-end;
    object-fit: contain;
    inline-size: auto;
    inset-block-start: -3rem;
    inset-inline-end: 0;
    block-size: 140%;
    max-inline-size: unset;
}

.personDetails {
    position: absolute;
    width: 60%;
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    color: #fff;
    top: 0;
    right: 0;
    z-index: 1;
}

.personDetails h3 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: end;
}

.personDetails p {
    font-size: 1.2rem;
    font-style: italic;
    text-align: end;
}

.tabs {
    display: flex;
    gap: .75rem;
}

.tab {
    flex: 1;
    padding: .5rem;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: .5rem;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    text-transform: capitalize;
}

.tab.active {
    background: #fff;
    color: var(--selection-dark);
}

.tabContent {
    height: 60%;
    color: #fff;
    display: flex;
    flex-direction: column;
    /* padding-top: 1rem; */
}

.tabContent ol {
    list-style-type: circle;
}

.tabContent ol.animate li {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s ease-in, transform 0.5s ease-out;
}

.tabContentList {
    opacity: 0;
}

/* .tabContent ol li{
    opacity: 0;
    padding: .25rem;
    transform: translateX(-100px);
} */

.tabContent p {
    width: 100%;
}
