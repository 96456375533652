.container {
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.plotContainer {
    padding: 0.625rem;
    background-color: var(--panel-dark-background);
}

.plotHeading {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    font-weight: 500;
}

.plotStoryline {
    font-size: 0.75rem;
    margin-top: 0.5rem;
}

.instruction {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0.75rem 0;
}

.plots,
.storyline {
    font-size: 0.75rem;
    background-color: var(--panel-dark-background);
    padding: 0.625rem;
}

.plotsMain {
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
    overflow: auto;
    height: 73%;
    flex: 1;
}

.plotActions {
    padding: 0.25rem;
    margin-bottom: 1rem;
}

.action {
    font-size: .875rem;
    line-height: 1.5rem;
    height: max-content;
}

.actionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
}

.plotInstruction {
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0.5rem 0;
}

.storylineMain {
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
    margin-top: 1rem;
}

.dialoguesContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
}

.storylineMain {
    flex: 1;
    overflow: auto;
    margin-bottom: 1rem;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.selected {
    border: solid 2px var(--selection-dark);
    border-radius: 0.5rem;
    background-color: var(--selection-light);
}