.ratingsLikesViews {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.ratingsLikesViewsContent {
    text-align: center;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.icon {
    width: 50%;
}

.starImage {
    color: var(--selected-theme-color);
}

.heartImage {
    color: red;
}

.eyeImage {
    color: white;
}

@media (max-width: 480px) {
    .ratingsLikesViews {
        flex-wrap: nowrap;
        gap: 0.5rem;
    }
}

@media screen and (min-width: 480px) and (max-width: 820px) {
    .ratingsLikesViews {
        flex-wrap: nowrap;
        gap: 0.5rem;
    }
}

@media screen and (min-width: 820px) and (max-width: 1200px) {
    .ratingsLikesViews {
        flex-wrap: nowrap;
        gap: 0.5rem;
    }
}