.main {
  width: 100%;
  height: 3.2rem;
  background-color: var(--header-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 3rem;
  z-index: 100;
  position: fixed;
  top: 0;
}

.designerMain {
  width: 100%;
  height: 3.2rem;
  background-color: var(--header-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1rem;
  z-index: 100;
  position: fixed;
  top: 0;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.logoImage {
  cursor: pointer;
  width: 1.75rem;
}

.navMenu {
  display: flex;
  flex-direction: row;
  font-family: "Poppins";
  font-size: 0.875rem;
  gap: 2.5rem;
}

.navMenuItem {
  font-size: 0.875rem;
  font-weight: var(--font-weight-medium);
  color: rgb(76, 38, 100);
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.navMenuItem .item:hover {
  border-bottom: 1px solid var(--selection-dark);
}

.navMenuItemActive {
  font-size: 0.875rem;
  font-weight: var(--font-weight-medium);
  color: #db6412;
  cursor: pointer;
}

.logoutContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.parentNavMenu {
  position: relative;
}

.parentNavMenu span {
  display: flex;
  align-items: center;
}

.subMenu {
  position: absolute;
  display: none;
  flex-direction: column;
  margin-top: 1rem;
  background-color: var(--header-color);
  width: max-content;
  min-width: 100%;
}

.subMenuStyle {
  position: absolute;
  left: 103%;
  top: -0.5rem;
}

.subMenu li {
  font-size: 0.875rem;
  font-weight: var(--font-weight-medium);
  color: rgb(76, 38, 100);
  cursor: pointer;
  padding: 0.5rem;
  border: unset;
}

.subMenu li:hover {
  border: unset;
  background-color: var(--dropDown-menu-hover);
}

.subMenuSelected {
  border: unset;
  background-color: var(--dropDown-menu-hover);
}

.showSubMenu {
  display: flex;
}

.hideSubMenu {
  display: none;
}

.sideBarMenuItem {
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  color: rgb(76, 38, 100);
  cursor: pointer;
  border-bottom: 1px solid transparent;
  margin-top: 1rem;
}

.userDetails {
  display: flex;
  align-items: center;
}

.userDetails > div {
  margin-right: 0.25rem;
}

.cartContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.cartIcon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.cartCount {
  transform: scale(0.75);
  position: absolute;
  right: -0.5rem;
  top: -0.8rem;
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
}

.notificationsContainer {
  margin-right: 0.5rem;
}

.notificationWrapper {
  max-width: 20rem;
  max-height: 25rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  gap: 1rem;
  padding: 1.75rem;
}

.NotificationWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 90%;
}

.notificationHeading {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.notificationHeading h1 {
  font-size: 1rem;
  color: var(--heading-dark-color);
  margin: 0;
}

.notifications {
  flex: 1;
  display: grid;
  gap: 1rem;
  overflow-y: auto;
}

.notifications::-webkit-scrollbar {
  width: 5px;
}

.notifications::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #1c105c;
}

.linkButtonsWrapper {
  display: flex;
  gap: 1rem;
}

.notifications::-webkit-scrollbar-thumb {
  border-radius: 18px;
  background-color: #db6412;
}

.notificationTitle {
  font-size: 0.75rem;
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.notification {
  font-size: 0.75rem;
  width: fit-content;
  padding: 0.5rem;
  display: flex;
  gap: 0.75rem;
}

.boldText {
  font-weight: var(--font-weight-bold);
}

.notificationActions {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.notificationActions button {
  font-size: 0.7rem;
  padding: 0.25rem;
  height: 2rem;
}

.userDetailsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 0.3rem;
  border-radius: 50px;
}

.userImage {
  box-sizing: border-box;
  width: 2.5rem !important;
  height: 2.5rem !important;
  border: 2px solid var(--selection-dark);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0.5rem;
}

.approvedNotificationWrapper {
  display: grid;
}

.userPhoto {
  padding: 0;
}

.userImage img {
  object-fit: contain;
}

.sidebarMenu {
  display: none;
}

.dropDownchildrenClass {
  right: 0;
}

.logoImageDesigner {
  cursor: pointer;
  width: 1.75rem;
}

.welcomeModalMain {
  width: 100%;
  display: grid;
  gap: 1.25rem;
  padding: 1rem 0;
}

.welcomeModalImageContainer {
  width: 100%;
}

.welcomeModalImageContainer img {
  width: 100%;
  object-fit: contain;
}

.welcomeModalContent h3 {
  font-weight: 500;
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.welcomeModalContent p {
  font-size: 1.25rem;
}

.welcomeModalFooter {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  position: sticky;
  bottom: 0;
}

.welcomeModalFooter div {
  flex-grow: 1;
}

.welcomeSecondaryBtn {
  background: #fff;
  border-color: var(--selection-dark);
  color: var(--selection-dark);
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  width: 100%;
}

.welcomePrimaryBtn {
  background: var(--selection-dark);
  border-color: var(--selection-dark);
  color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  width: 100%;
}

.welcomeSecondaryBtn:hover {
  background: var(--selection-dark) !important;
  border-color: var(--selection-dark);
  color: #fff;
}

.welcomePrimaryBtn:hover {
  background: #fff !important;
  border-color: var(--selection-dark);
  color: var(--selection-dark);
}

.bookPodcast {
  flex: 1;
  --webkit-box-flex: 1;
  width: 100%;
  border-radius: 0.5rem;
  height: 2.8rem;
  margin: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.bookPodcast a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  padding-inline: 0.5rem;
  border-radius: 0.5rem;
  height: 100%;
  max-width: max-content;
  font-size: 0.875rem;
  font-weight: var(--font-weight-medium);
  color: rgb(76, 38, 100);
  cursor: pointer;
  text-decoration: none;
}

.bookPodcast img {
  height: 100%;
  object-fit: contain;
}

@media (min-width: 1024px) {
  .welcomeModalMain {
    grid-template-columns: 0.75fr 1.25fr;
  }
}

@media screen and (max-width: 1000px) {
  .sidebarMenu {
    display: block;
  }

  .navMenu {
    display: none;
  }

  .logoImage {
    display: none;
  }

  .cartCount {
    right: -0.875rem;
    top: -1rem;
  }
}

@media screen and (max-width: 600px) {
  .dropDownchildrenClass {
    right: -7rem;
  }
}

@media screen and (max-width: 500px) {
  .createBook {
    display: none;
  }

  .logoImageDesigner {
    display: block;
    cursor: pointer;
    width: 1.75rem;
    margin-left: -2rem;
  }

  .bookTitle {
    width: 100%;
    text-align: center;
  }
}
