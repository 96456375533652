.message p {
    color: var(--text-light-color) !important;
    font-size: 1rem;
}

.actions {
   position: absolute;
   right: 2rem;
   bottom: 2rem;
}

.actions button:nth-child(2) {
    margin-left: 7px;
}