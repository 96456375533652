

.panel-button {
	height: 50px;
	width: 60px;
	position: relative;
    color: var(--text-light-color);
	text-align: center;
	font-size: 28px;
	line-height: 32px;
}

.panel-button span {
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
}

.panel-button .x-btn-inner {
    font-size: 12px;
    padding: 0;
    position: relative;
    bottom: -28px;
    user-select: none;
}

.panel-button .x-btn-pointer {
	display: none;
	width: 15px;
    height: 15px;
    transform: rotate(45deg) translateX(-24px);
    background-color: var(--panel-light-background);
	box-shadow: 1px 0px 10px 2px rgba(0, 0, 0, 0.5);
}

.show-inner-menu .x-btn-pointer {
	display: block;
}

.panel-button.show-inner-menu,
.panel-button:hover {
	color: #fff;
}

.panel-button span.icomoon {
	font-size: 60px;
}

.panel-button.icomoon::before {
	position: absolute;
	left: calc(50% - 30px);
	left: -webkit-calc(50% - 30px);
	left: -moz-calc(50% - 30px);
	top: calc(50% - 30px);
	top: -webkit-calc(50% - 30px);
	top: -moz-calc(50% - 30px);
}

.panel-button.x-item-disabled {
	opacity: 0.3 !important;
	pointer-events: none;
}
