.toolbar-button {
	color: #707070;
	height: 5.625rem;
	text-align: center;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 0.25rem;
	margin-bottom: 1rem;
	cursor: pointer;
}

.x-btn-pressed {
	background-color: white;
	color: var(--selection-dark);
}

.iconBox {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	background: white;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.title {
	margin-top: 0.25rem;
	font-size: 0.875rem;
}

.x-btn-pressed>.iconBox {
	background: #F3E7FF;
}

.x-btn-pressed>.title {
	color: #101010;
}