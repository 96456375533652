.container {
    display: grid;
    gap: 1rem;
}

.headingAndDescriptionWrapper {
    display: grid;
}

.heading {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    color: var(--heading-dark-color);
    margin: 0;
}

.headingAndButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.headingTitle {
    color: rgba(0, 0, 0, 1);
    font-size: 1rem;
    font-weight: var(--font-weight-regular);
}

.helloUserWrapper {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.75);
}

.disabled {
    display: none;
}

.popup {
    font-size: 0.75rem;
    color: orange;
    padding: 0.5rem;
    text-align: center;
}

@media screen and (min-width: 480px) and (max-width: 820px) {

    .actionBtn {
        padding: 0.25rem 0.25rem;
        font-size: 0.875rem;
    }
}

@media screen and (min-width: 360px) and (max-width: 480px) {
    .actionBtn {
        padding: 0rem 1rem;
        font-size: 0.75rem;
    }

    .headingWrapper {
        width: 22rem;
    }
}