.main {
    padding: 0.5rem 2.5rem;
    background: white;
    border-radius: 24px;
    box-shadow: 0px 3px 6px #00000029;
}

.left {
    z-index: 10;
    height: 10rem;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.pointLogo {
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 10px solid rgb(242, 242, 242);
}

.leftcontent {
    margin: 0 1.5rem;
    width: 17rem;
}

.content {
    margin: 0 1.5rem;
}

.description {
    font-weight: var(--font-weight-bold);
}

.title {
    font-weight: bold;
    font-size: 1.75rem;
}

@media screen and (min-width: 481px) and (max-width: 820px) {
    .main {
        width: 50%;
    }

    .left {
        width: 100%;
    }

    .pointLogo>img {
        width: 40%;
    }

    .pointLogo {
        width: 5rem;
        height: 5rem;
    }

}

@media (max-width: 480px) {
    .main {
        width: 65%;
    }

    .left {
        width: 100%;
        height: 7.125rem;
    }

    .pointLogo {
        width: 4rem;
        height: 4rem;
        min-width: 4rem;
    }

    .pointLogo > img{
        width: 2.8rem;
        object-fit: contain;
    }

    .title {
        font-weight: bold;
        font-size: 1.375rem;
    }

    .numberImage>img {
        width: 3rem;
        height: 3rem;
    }

    .description {
        font-size: 0.8rem;
    }
}