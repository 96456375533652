@font-face {
    font-family: "Poppins";
    src: url("./Poppins-Thin.ttf");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-ThinItalic.ttf");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-ExtraLight.ttf");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-ExtraLightItalic.ttf");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-LightItalic.ttf");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-Italic.ttf");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-MediumItalic.ttf");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-SemiBoldItalic.ttf");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-ExtraBold.ttf");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-ExtraBoldItalic.ttf");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-Black.ttf");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-BlackItalic.ttf");
    font-weight: 900;
    font-style: italic;
}
