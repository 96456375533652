.main {
    background-color: transparent;
    margin-left: -0.25rem;
    display: inline-block;
}

.main button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.btnWrapper {
   height: 1rem;
   display: flex;
    align-items: center;
}

.btnWrapper img {
    vertical-align: middle;
}

.btnWrapper span {
    display: inline;
    color: var(--heading-dark-color);
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
}