div.action-panel {
	padding: 0;
	width: max-content;
	text-align: center;
}

.action-panel.separator-top {
	padding-top: 6px;
	border-top: 1px solid rgb(157, 159, 160);
}

.x-panel-header-title-default {
	user-select: none;
}

.tool-panel-container {
	flex: 1;
	border-right: 1px solid #9d9fa0;
}

.text-view-btn-style1 {
	width: 45px;
	height: 42px;
	position: relative;
}

.text-view-btn-style2 {
	width: 70px;
	height: 50px;
	position: relative;
}

.img-curved-text-inside {
	background-image: url('../../resources/images/CurvedTextPlacementInside.svg') !important;
	background-repeat: no-repeat !important;
	background-position: center;
	background-size: 5.5rem;
}

.img-curved-text-outside {
	background-image: url('../../resources/images/CurvedTextPlacementOutside.svg') !important;
	background-repeat: no-repeat !important;
	background-position: center;
	background-size: 5.5rem;
}

.img-curved-text-inside-selected {
	background-image: url('../../resources/images/CurvedTextPlacementInsideSelected.svg') !important;
	background-repeat: no-repeat !important;
	background-position: center;
	background-size: 5.5rem;
}

.img-curved-text-outside-selected {
	background-image: url('../../resources/images/CurvedTextPlacementOutsideSelected.svg') !important;
	background-repeat: no-repeat !important;
	background-position: center;
	background-size: 5.5rem;
}

.zoom-view-btn-style {
	width: 100%;
	text-align: left !important;
	padding: 5px 10px;
	margin-bottom: 10px;
	display: flex !important;
	justify-content: flex-start;
	align-items: center;
}

.dark-background {
	background-color: var(--panel-dark-background);
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
}

.semi-light-background {
	background-color: var(--panel-semi-light-background);
}

.semi-dark-background {
	background-color: var(--panel-semi-dark-background);
	margin-top: 1rem;
}

.font-22 {
	font-size: 22px !important;
}

.font-28 {
	font-size: 28px !important;
}

div.MuiOutlinedInput-input {
	padding: 12px 14px !important;
}

.image-item-thumb-wrap.x-item-selected {
	outline: 3px solid var(--selection-dark);
	border: 2px solid white;
	border-radius: 2px;
}

.texture.x-item-selected {
	outline: 3px solid var(--selection-dark);
	border-radius: 2px;
}

.clipart-category-btn {
	border: none;
	width: 24px;
	height: 18px;
	border-radius: 5px;
}

.clipart-category-btn:hover {
	opacity: 0.8;
}

.edit-svg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background-color: #ddd;
	padding: 5px;
}

.hyperlink {
	color: blue;
	cursor: pointer;
}

.hyperlink:hover {
	text-decoration: underline;
}

.manage-art-item {
	background-color: gray;
	display: inline-block;
	padding: 8px;
	margin: 5px;
	border-radius: 10%;
}

.manage-art-item.x-item-selected {
	outline: 3px solid #389ae8;
}

span.color-item-thumb-wrap {
	position: relative;
	margin: 0px 2px;
}

span.color-item-thumb-wrap img.locked {
	position: absolute;
	background-color: black;
	border: 2px black solid;
	width: 0.7rem;
	top: 0;
	left: 0;
}

span.color-item-thumb-wrap img.mesh {
	position: absolute;
	background-color: black;
	width: 0.7rem;
	height: 0.8rem;
	top: 0;
	left: 0.7rem;
}

.MuiDialog-root span {
	cursor: default;
	user-select: none;
}

.MuiOutlinedInput-root.MuiOutlinedInput-multiline {
	padding: 10px;
}

.MuiSlider-valueLabel span span {
	color: #fff;
}

.bi-end-Slider.second .MuiSlider-thumbColorPrimary[data-index="1"],
.bi-end-Slider.first .MuiSlider-thumbColorPrimary[data-index="0"] {
	display: none;
}

.MuiInputBase-root.MuiOutlinedInput-root {
	border-radius: 0;
}

.MuiTabs-root>div>span {
	background-color: var(--selection-dark);
}

.MuiDialog-paper.MuiPaper-root {
	background-color: var(--panel-semi-dark-background);
	padding: 0.5rem;
}

.MuiSvgIcon-root,
.MuiDialogTitle-root {
	color: #cecfd1;
}

div.image-item-thumb-wrap.shapeItem {
	width: 46%;
	height: 72px;
	background-color: gray;
	background-size: contain;
	border: 2px solid white;
	background-position: center;
	background-repeat: no-repeat;
}

.editor-text {
	font-size: 14px !important;
	color: var(--text-light-color);
}

.editor-font-color {
	color: var(--text-light-color);
}

/*ws-button css  */

.icomoon {
	font-family: 'icomoon';
}

.icomoon2 {
	font-family: 'icomoon2' !important;
}

.icomoon5 {
	font-family: 'icomoon5' !important;
}

.previewCanvasContainer {
	border: 1px solid #9d9fa0;
	box-sizing: content-box;
	background: var(--panel-semi-dark-background);
	text-align: center;
	visibility: hidden;
	touch-action: pan-x pan-y pinch-zoom;
}

.previewCanvasLabel {
	font-size: 10px;
	border-top: 1px solid #9d9fa0;
	font-weight: normal;
	user-select: none;
	background: white;
	display: none;
}

/*  */

div.image-item-thumb-wrap {
	width: 100%;
	height: 100%;
	position: relative;
	display: inline-block;
	background-color: white;
	user-select: none;
}

div.image-item-thumb-wrap .image-item-thumb {
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	object-fit: cover;
	cursor: pointer;
	border: 2px solid transparent;
	outline: 2px solid transparent;
}

div.image-item-thumb-wrap .image-item-thumb:hover {
	outline: 2px solid var(--selection-dark);
	border-radius: 4px;
}


div.image-item-text-wrap {
	margin-left: -15px;
}

div.clipart-item-thumb-wrap {
	width: 80px;
	height: 80px;
	display: inline-block;
	margin-bottom: 3px;
	padding: 2px;
	user-select: none;
}

div.clipart-item-thumb-wrap img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

span.color-item-thumb-wrap {
	display: inline-block;
	user-select: none;
}

span.color-item-thumb-wrap span {
	display: block;
	font-size: 0;
	border: 1px solid #5c5c5c;
	height: 1.2rem;
	border-radius: 3px;
	width: 1.2rem;
}

span.color-item-thumb-wrap.x-item-selected span {
	outline: 2px solid var(--selection-dark);
}

.color-added-notification {
	margin-right: auto;
}

.emptyMessage {
	font-size: 1rem;
	opacity: 0.5;
}



.itemsWrapper {
	margin-top: 1rem;
	display: flex;
	flex-wrap: wrap;
	padding-top: 5px;
	justify-content: center;
	gap: 5px;
}

.itemWrap {
	width: 100%;
	height: 100px;
	background-color: gray;
	background-size: contain;
	border: 2px solid white;
	background-position: center;
	background-repeat: no-repeat;
	object-fit: contain;
}

.zooming-btn-wrapper {
	display: flex;
	gap: 1rem;
	font-size: 1.25rem;
	padding: .5rem;
	justify-content: space-around;
	background-color: #fff;
}

@media screen and (max-width: 500px) {
	.semi-dark-background {
		margin-top: 0rem;
	}
}


/* fullscreenbackdrop */

:not(:root):fullscreen::backdrop {
	background: var(--panel-dark-background);
}