.container {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 0 10px;
    min-height: fit-content;
}

.container.hidden {
    display: none;
}

.heading {
    font-size: 1.25rem;
    font-weight: var(--font-weight-bold);
    margin-bottom: 1rem;
    color: #231375;
}

.titleContainer {
    height: 2rem;
    flex: none;
}

@media screen and (max-width: 500px) {
    .titleContainer {
        height: 0.75rem;
    }
}