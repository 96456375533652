.cartContent {
    width: 100%;
    background: var(--background-color);
    padding: 3rem 0;
    box-sizing: border-box;
}

.mainContainer {
    margin: auto;
    width: 85%;
    display: grid;
    grid-template-rows: 3.5rem auto 1.5rem;
    margin-top: 2rem;
}

.heading {
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: center;
}

.cartContainer {
    column-gap: 2rem;
    display: flex;
    flex-wrap: wrap;
}

.cartHeading {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    color: var(--heading-dark-color);
}

.wallet {
    background: #fffcec;
    border-radius: 2rem;
    padding: 7px 1rem;
    justify-content: space-between;
    width: 51%;
    display: flex;
}

.orderDetailsContainer {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 2rem;
    height: fit-content;
    flex: 0.4;
}

.cartDetailsContainer {
    padding: 3rem;
    min-width: 35rem;
    flex: 0.6;
    display: grid;
    gap: 1.5rem;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
    background-color: white;
    margin-bottom: 1.5rem;
}

.cartTableWrapper {
    background-color: white;
    padding: 2rem;
    flex: 0.6;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.orderRows {
    display: grid;
    grid-template-columns: 1fr 0.75fr;
}

.cartItemDetail {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 0.5rem;
}

.cartItemCountContainer {
    width: fit-content;
    height: fit-content;
    display: flex;
    gap: 0.5rem;
    padding: 0.2rem;
    border-radius: 25px;
    background-color: var(--background-color);
    align-items: center;
}

.cartItemImage>img {
    width: 3.5rem;
    height: 4.8rem;
    border: 1px solid black;
    border-radius: 5px;
}

.bookDetailsWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
}

.cartItemTitle {
    font-size: 0.875rem;
    font-weight: var(--font-weight-bold);
}

.cartItemDescription {
    font-size: 0.75rem;
}

.cartItemCount {
    font-size: 1rem;
    width: 2.5rem;
    background: white;
    border: none;
    outline: none;
    padding: 0.1rem;
    border-radius: 25px;
    text-align: center;
}

.orderSubtotal {
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
}

.headerText {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 0.65rem;
    font-weight: 600;
}

.itemText {
    text-align: center;
    margin-top: 1rem;
    background-color: #f6f6f6;
    gap: 1rem;
}

.itemText:last-child {
    border-radius: 0 8px 8px 0;
    padding-top: 0.5rem;
    vertical-align: top;
}

.itemLabel {
    text-align: left;
    font-family: Poppins;
    font-size: 1rem;
}

.couponSummaryRows {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}

.promoCode {
    background-color: #dfdfdf;
    width: 100%;
    padding: 0.4rem;
}

.backButton {
    display: inline-block;
}

.cancelBtn {
    display: inline-block;
    width: 0.65rem;
    height: 0.65rem;
    cursor: pointer;
}

.orderDetails {
    padding: 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0.75rem 0;
}

.emptyCartMessage {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 3rem;
    gap: 2rem;
}

.messageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emptyCartHeading {
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
}

.emptyCartdescription {
    font-size: 0.875rem;
}

.CouponSummaryMain {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.couponInputWrapper {
    display: flex;
    flex-direction: column;
}

.selectpromoCode {
    display: flex;
    gap: 0.5rem;
}

.promoCodeInputWrapper {
    display: flex;
    gap: 1.5rem;
}

.promoCodeLabel {
    font-size: 0.875rem;
    color: #8B8B8B;
}

.couponCode {
    margin-top: 1rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.hiddenCouponCode {
    display: none;
}

.tooltip {
    margin-top: 0.3rem;
    font-weight: 500;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.5rem;
    color: #707070;
    font-size: 0.75rem;
}

.disclaimerMessage .tooltiptext {
    visibility: hidden;
    width: 20rem;
    background: #FFF6F0;
    border: 1px solid #8B8B8B;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: -5%;
    margin-left: -60px;
}

.disclaimerMessage img {
    width: 100%;
    transform: rotate(180deg);
    filter: invert(0.4);
}

.disclaimerMessage .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #8B8B8B transparent;
}

.disclaimerMessage:hover .tooltiptext {
    visibility: visible;
}

.disclaimerMessage {
    cursor: pointer;
    font-size: 0.75rem;
    width: 1rem;
    color: black;
    height: 1rem;
    display: flex;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.appliedCouponPopUP {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-self: center;
    justify-content: center;
    text-align: center;
    width: 14rem;
}

.couponSuccess {
    color: green;
    font-size: 1rem;
    font-size: var(--font-weight-semiBold);
}

.savedAmount {
    font-size: 2rem;
    font-weight: var(--font-weight-bold);
}

.savingMessageWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
}

.savedAmountMessage {
    font-size: 0.875rem;
}

.cartItemCard {
    background: #f6f6f6;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 0.75rem;
    border-radius: 8px;
}


.cartItemNewImage>img {
    width: 5.5rem;
    height: 8rem;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
}

.pricing {
    display: grid;
    grid-template-columns: auto;
}

.amount {
    display: flex;
    align-items: center;
}

.displayGridTwoByTwo {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 0.5rem;
    font-size: 0.8rem;
    color: #707070;
}

.cartBookDetails {
    display: flex;
    gap: 1rem;
}

.pricingAndCancelBtnWrapper {
    display: flex;
}

.totalAmount {
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
    display: flex;
    align-items: center;
}

.totalAmountWrapper {
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 600px) and (max-width: 965px) {
    .cartContainer {
        flex-wrap: nowrap;
    }

    .mainContainer {
        width: 95%;
        margin-top: 1rem;
    }

    .tooltiptext {
        left: 5% !important;
        top: 102% !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
    .orderDetailsContainer {
        margin-top: 1rem;
        width: 100%;
    }

    .tooltiptext {
        left: 14% !important;
        top: 100% !important;
    }

    .mainContainer {
        padding: 1rem;
        width: 90%;
        margin-top: 0.5rem;
        grid-template-rows: 2.5rem auto 1.5rem;
    }

    .cartTableWrapper {
        padding: 0.75rem;
    }

    .cartContainer {
        margin-top: 0rem;
        column-gap: 0rem;
    }

    .cartContainer {
        margin-top: 0rem;
        column-gap: 2rem;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .emptyCartMessage {
        width: 100%;
        padding: 1.75rem 0;
        gap: 2rem;
    }

    .itemText:last-child {
        padding-right: 0.5rem;
    }

    .orderDetailsContainer {
        row-gap: 1rem;
    }
}