.paymentResponseContainer .spinner {
    display: flex;
    justify-content: center;
}

.paymentResponseContainer .spinner img {
    width: 60px;
}

.paymentResponseContainer h3 {
    text-align: center;
    font-weight: normal;
}

.paymentResponseContainer {
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    height: 90vh;
    padding: 2rem 0 4rem 0;
    box-sizing: border-box;
    margin-top: 3.2rem;
}