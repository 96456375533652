.toolbarContainer {
    overflow-y: auto;
    width: 6.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
}

.container {
    display: block;
    overflow-y: auto;
    background-color: var(--panel-semi-dark-background);
    /* padding-top: 1rem; */
}

/* .container>div:first-child {
    height: 1rem;
} */

.container>div:not(:first-child) {
    height: 91%;
}

.cancelActionContainer {
    display: flex;
    justify-content: end;
    height: 1rem;
}

.cancelActionContainer>img {
    cursor: pointer;
}

.boxShadow {
    box-shadow: 0 4px 2px -2px gray;
}

.leftHeading {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    background-color: var(--panel-semi-dark-background);
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 1rem 10px;
}

.heading {
    font-size: 1.25rem;
    font-weight: var(--font-weight-bold);
    color: #231375;
}

@media screen and (min-width: 960px) {
    .container {
        position: fixed;
        width: 22rem;
        z-index: 1;
        left: 7.6rem;
        height: 100%;
        transition: 450ms;
    }
}

@media screen and (max-width: 500px) {
    .toolbarContainer {
        padding-top: 0.75rem;
    }
}