.dropDown {
    position: relative;
    height: 100%;
    padding-left: 1.2rem;
    display: inline-block;
}

.selectedOption {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    cursor: pointer;
}
.selectedOptionOpen {
    display: flex;
    align-items: center;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.dropIcon {
    width: 0.625rem;
    height: 0.35rem;
}

.selectedDropImage {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    margin-right: 8px;
    background-color: brown;
    color: white
}

.dorpItems {
    position: absolute;
    top: 2.25rem;
    left: 0.25rem;
    width: 115%;
    background-color: var(--input-background-color);
    border: 1px solid black;
    padding: 0;
    margin: 0;
    list-style: none;
    padding: 0.3rem;
    gap: 0.3rem;
    display: grid;
    z-index: 1;
}

.dorpItems li {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    padding: 0.2rem;
    font-weight: var(--font-weight-semiBold);
}

.dorpItem {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    padding: 0.2rem;
}

.dorpItem:hover {
    background-color: #DAD9D9;
}

.selectedDropItem {
    background-color: #4d4d4d;
}

.itemImage {
    width: 24px;
    border-radius: 6px;
    height: 24px;
    margin-right: 8px;
}