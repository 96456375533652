.checkoutForm {
  max-width: 500px;
  margin: 0 auto;
}
.checkoutForm .closeModalIcon{
  position: absolute;
  right: 30px;
  top: 5px;
  font-weight: normal;
  cursor: pointer;
}
.checkoutForm .formTitle {
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
  margin-bottom: 20px;
}

.checkoutForm .formGroup {
  margin-bottom: 1rem;
}


.checkoutForm label {
  display: block;
}

.checkoutForm select {
  height: 2.25rem;
  width: 100%;
  padding-left: 1.25rem;
  font-size: 0.875rem;
  font-family: 'Poppins';
  background: #FAFAFA;
  border: 1px solid #F1F0F0;
  border-radius: 4px;
  outline: none;
}



.checkoutForm select:focus {
  border: 1px solid #FB7E05;
  background: #FFF3DF;
}

.stateListLabel {
  font-size: 0.875rem;
}

.checkoutForm .formMessage {
  padding: 10px;
  width: 100%;
  display: block;
}

.checkoutForm .success {
  color: green;
}

.checkoutForm .error,
.required {
  color: red;
}

.checkOutHeading {
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
  color: var(--heading-dark-color);
  margin: 0;
}

.checkoutForm .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}