.main {
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding: 2rem 0 4rem 0;
    box-sizing: border-box;
    margin-top: 3.2rem;
}

.tableWrapper {
    background: inherit;
}

.backButtonWrapper {
    width: 85%;
    display: flex;
    align-items: start;
}

.container {
    box-sizing: border-box;
    width: 85%;
    padding: min(4rem, 7vw) min(6rem, 8vw);
    background-color: white;
    border-radius: 8px;
}

.contentWrapper {
    display: grid;
    gap: 2rem;
    width: 100%;
    grid-template-columns: 100%;
}

.staticContentMain {
    display: grid;
    gap: 1.5rem;
}

.staticContentHeading {
    font-size: 1.5rem;
    font-weight: var(--font-weight-semiBold);
    margin: 0;
    color: var(--heading-dark-color)
}

.description {
    font-size: 1rem;
}

.staticContentDescription {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.refundList {
    margin: 0;
    margin-left: 2rem;
    list-style-type: disc;
}

.secondTitle {
    margin: 0;
}

.italicPara {
    margin: 0;
    font-style: italic;
}

.boldText {
    font-weight: var(--font-weight-bold);
}

.bookPriceTable {
    border-spacing: 0 10px;
}

.tablePurpleHeading {
    font-size: 0.875rem;
    font-weight: 500;
    background: var(--app-dark);
    height: 2rem;
    color: white;
}

.tablePurpleHeading:first-child {
    border-radius: 4px 0 0 4px;
    padding-left: 1.5rem;
    text-align: left;
}

.tablePurpleHeading:last-child {
    border-radius: 0 4px 4px 0;
}

.pages {
    width: 20rem;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 4px 0 0 4px;
    background: var(--app-dark);
    text-align: left;
    height: 2rem;
    color: white;
    padding-left: 1.5rem;
}

.centerCellHeading {
    width: max-content;
    font-size: 0.875rem;
    font-weight: 500;
    background: var(--app-dark);
    padding: 0 .5rem;
    height: 2rem;
    color: white;
}

.price {
    width: max-content;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 0 4px 4px 0;
    background: var(--app-dark);
    height: 2rem;
    color: white;
}

.tableCell {
    text-align: center;
    padding: 0 .5rem;
}

.quantityCellLabel {
    vertical-align: top;
    padding-left: 1.5rem;
}

.quantityCell {
    display: grid;
    text-align: center;
    grid-template-columns: auto;
    gap: 0.5rem;
}

.tableCell:first-child {
    text-align: left;
    padding-left: 1.5rem;
}

.type {
    width: 15rem;
}

.discount {
    width: 20rem;
}

.MRP {
    width: 15rem;
}

.royalty {
    width: 10rem;
}

.amount {
    width: 10rem;
}

.link {
    color: blue;
    cursor: pointer;
}

.link:hover {
    border-bottom: 1px solid blue;
}

.interpretationAndDefinitions {
    margin: 0;
}

.definitionList {
    margin: 0;
    margin-left: 1.2rem;
    list-style: disc;
}

.cookiesListItems {
    display: flex;
    flex-direction: column;
}

.mostRecommendedRow {
    width: 100%;
    border: 1px solid rgba(163, 161, 161, 1);
    border-radius: 4px;
}

.mostRecommendedLabel {
    position: relative;
    font-size: 0.75rem;
    left: 0.5rem;
    top: -0.6rem;
    display: flex;
    padding: 0 0.5rem;
    width: fit-content;
    color: var(--selection-dark);
    background-color: white;
    margin-bottom: -0.6rem;
}

@media screen and (max-width: 480px) {
    .main {
        gap: 1rem;
        margin-top: 2.2rem;
    }

}