.section1 {
    background-color: rgb(63, 33, 123);
}

.header {
    display: flex;
    padding: 2rem;
}

.header img{
    width: 5rem;
}
.navBar{
    margin-left: 2rem;
}
.headingContainer {
    display: flex;
    justify-content: right;
    flex-wrap: wrap;

}

.heading1 {
    font-size: 3.7rem;
    color: white;
    font-weight: bold;
    text-align: center;
}

.link {
    font-size: 1.5rem;
    color: orange;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
}
.link:hover{
    text-shadow: 2px 2px 4px #000000;
}

.alooza {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 18rem;
    margin-left: 15rem;
    margin-top: 10rem;
    margin-right: 2rem;
}

.section2 {
    background-color: rgb(0, 40, 152);
    margin: auto;
}

.content {
    color: white;
}
.unlockContentLeft{
    font-size: 1.3em;
    width: 80%;
}

.platformHeading{
    font-size: 3rem;
    color: white;
    font-weight: bold;
}

.potentialContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.potentialContent{
   display: flex;
   flex-direction: column;
   gap: 2rem;
   color: white;
}

.potentialContent h1{
    font-size: 2.5rem;
    text-align: center;
}

.unlockContent{
    width: 70%;
}

.publishContainer{
    display: flex;
    gap: 5rem;
    flex-wrap: wrap;
    margin: auto;

}

.formContainer{
    display: flex;
    gap :5rem;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2rem;
}

.workShopFormsContainer{
    border: 3px solid var(--landingPage-text);
    border-radius: 5px;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.workShopFormsContainer h2{
    text-align: center;
    color: var(--landingPage-text);
}

.formInputBox{
    gap: 1rem;
    height: 100%;
}

.formInputBoxStudent{
    height: 100%;
    gap: 0.5rem;
}

.formInputBox div:last-child {
   margin: auto;
  }

.formInputBox input{
    height: 3rem;
}

.formInputBox select{
    height: 3rem;
}

.formButton{
    height: 3rem;
    background-color: var(--landingPage-form-buttom);
    transition: all 0.2s ease;
    border: none;
}


.formButton:hover:not([disabled]) {
    background-position: left bottom;
    background: var(--landingPage-form-buttom);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;

}

.formButton:active:not([disabled]) {
    outline: 0;
    background:  var(--landingPage-form-buttom);
    box-shadow: none;
}

.formButton:focus {
    outline: 0;
    box-shadow: none;
}

.formButton:disabled {
    opacity: 0.6;
    pointer-events: none;
}


.featureContainer{
    display: flex;
    gap:2rem;
    padding: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.joinContainer{
    display: flex;
    gap: 4rem;
    margin: auto;
    padding: 2rem;
    flex-wrap: wrap;
    justify-content:center ;
    align-items: center;
}
.registerButton button{
    height: 3rem;
    border: 1px solid white;
    border-radius: 1.75rem;
    padding: 0 1.5rem 0 1.5rem;
    background-color: white;
    color:  var(--landingPage-text);
    font-family: Poppins;
    font-weight: var(--font-weight-semiBold);
    font-size: 1rem;
    min-width: 5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.joinLeftPart{
    display: flex;
    gap: 2rem;
    flex-direction: column;
    
}

.joinLeftPart h1{
    color: white;
    text-align: center;
    
}

.joinRightPart{
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.joinRightPart h1{
    color: white;
    text-align: center;
    
}

.joinRightPart img{
    width: 300px;
}

.bookList {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}
.errorMsg{
    color: red;
}

.starAuthors>div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.starAuthors {
    display: flex;
    flex-direction: column;
}

.starAuthorContainer{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.whyBookAloozaImage img{
    width: 300px;
}

.authorImage {
    width: 4.5rem;
    height: 4.5rem;
    z-index: 10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.outerCircle{
    width: 5rem;
    height: 5rem;
    z-index: 9;
    border-radius: 50%;
    display: flex;
    border: 3px solid orange;
    justify-content: center;
    align-items: center;
}

.authorImage>img {
    width: 100%;
    border-radius: 50%;
}


.authorName {
    font-size: 1.2rem;
    font-weight: var(--font-weight-semiBold);
    color: white;
}

.eminentSpeakerContainer{
    background-color: #F5FBFF ;
    padding: 2rem;
   
}

.eminentSpeakers{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;  
    text-align: center;
}

.eminentSpeakersBox{
    display: flex;
    width: calc(100%/3);
    flex-direction: column;
    background-color: white;
    border-radius: 2px;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
}

.eminentImage{
    width: 8rem;
    height: 8rem;
    z-index: 10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.eminentImage>img {
    width: 100%;
    border-radius: 50%;
}

.eminentName{
    color: var(--landingPage-text);
    font-weight: bold;

}
.eminentDesignation{
    color: var(--landingPage-form-buttom);
    font-size: 0.75rem;
    text-align: center;
}

.schoolsListContainer{
    padding: 2rem;
}
.schoolsList{
    display: flex;
    gap: 6rem;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    padding: 2rem;
}

.newsLetterBox{ 
    background-color: #5A13B1;
    width: 14.5rem;
    padding: 2rem;
    display: flex;
   flex-direction: column;
   justify-content: space-between;
   color: white;
   text-align: center;


}

.earnContent{
    font-size: 2rem;
}

.subscribeForm{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   align-items: center;
   justify-content: center;
   
}
.subscribeForm div{
    width: 20rem;
}

.subscribeForm div:last-child{
    display: flex;
    justify-content: center;
}

.footer{
    display: flex;
    align-items: flex-end;
    justify-Content: center;
}

.footer img{
    width:50vw;
}

@media screen  and  (min-width: 820px){
    .potentialContainer{
        padding-left: 8rem;
        gap: unset;
    }

    .potentialContent{
        width: 50%;
    }
    .potentialContent h1{
       text-align: unset;
    }
    .navBar{
        margin-left: 8rem;
    }

    .header img{
        width: 10rem;
    }
    .header {
        padding: 2rem 6rem;
    }

    .heading1{
        text-align: unset;
    }

    .heroContainer{
        width: 41rem;
    }

    .link{
        text-align: unset;
    }
    .workShopFormsContainer{
        width: 30%;
    }

    .featureContainer{
        flex-wrap: nowrap;
        padding: 4rem;
    }
  

    .earnHeading{
        padding-left: 4rem;
         justify-content: left;
    }
    .earnContent{
        padding-left: 4rem;
    }
    .publishContainer{
            width: 75%;
            gap: 10rem;
            flex-wrap: nowrap;
    }

    .whyBookAloozaImage img{
        width: unset;
    }

    .joinContainer{
        flex-wrap: nowrap;
        width: 75%;
        gap: 8rem;
        padding-left: 8rem;
    }

    .joinLeftPart{
        width: 60%;
    }
    
    .starAuthorContainer{
        display: block;
    }

    .secondAuthor{
       position:relative;
       left:100%;
        margin:-2.5rem 0rem;
    }

    .joinRightPart h1{
    text-align: unset;
    
    }

     .joinLeftPart h1{
    text-align: unset;
    
    }

    .joinRightPart img{
        width: unset;
    }
   
    .eminentSpeakersBox{
         width: calc(100%/8);
    }

    .footer img{
        width:unset;
    }
    
    .schoolsList{
        padding: 2rem 10rem;
    }
}