.main {
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 0.5rem;
    gap: 0.25rem;
    align-items: center;
}

.text {
    font-size: 0.875rem;
}

.main:hover {
    background-color: var(--selection-light);
}

.icon {
    cursor: pointer;
    color: var(--button-color-yellow);
}