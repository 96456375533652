.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}

.main{
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 110;
    transform: translate(-50%,-50%);
    width: 4rem;
    height: 4rem;
    z-index: 101;
    background-color: transparent;
    border-radius: 50%;
}

.main span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: small;
    font-weight: var(--font-weight-bold);
    color: white;
}

.loader {
    border: 5px solid #EAF0F6;
    border-radius: 50%;
    border-top: 5px solid #0E5FBC;
    width: 4rem;
    height: 4rem;
    animation: spinner 1s linear infinite;
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}