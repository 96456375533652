.mainContainer {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
}

.pictureBox {
    padding-right: 2rem;
}

.detailsBox {
    flex: 0.9;
}

.reviewerName {
    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    margin-bottom: 0.2rem;
}

.reviewText {
    font-family: 'Poppins';
    color: #707070;
    font-size: 0.875rem;
    margin: 0.3rem 0;
}

.timeBox {
    font-size: 0.75rem;
    font-weight: var(--font-weight-regular);
    padding-top: 0.3rem;
}

.timeBox > p {
    font-family: Poppins;
    font-size: 0.75rem;
}