.main {
    display: grid;
    gap: 2rem;
}

.orderTableContainer {
    width: 100%;
}

.orderTable {
    width: 100%;
    border-collapse: collapse;
}

.orderTableHeading {
    height: 3rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
    color: white;
}

.tableRow {
    background: var(--app-dark);
    color: #fff;
}

.productColumnHeading {
    width: 35%;
    border-radius: 4px 0 0 4px;
    background: var(--app-dark);
    text-align: left;
    padding-left: 1.5rem;
}

.orderId {
    width: 8%;
    text-align: left;
    padding-left: 1.5rem;
    background: var(--app-dark);
    border-radius: 4px 0 0 4px;
}

.orderDate {
    width: 16%;
    background: var(--app-dark);
}

.quantity {
    width: 8%;
    background: var(--app-dark);
}

.status {
    padding: .5rem;
    border-radius: .5rem;
    text-transform: capitalize;
    width: fit-content;
    font-size: 0.75rem;
}

.quantityColumnHeading {
    width: 15%;
    background: var(--app-dark);
}

.priceColumnHeading {
    width: 13%;
    background: var(--app-dark);
}

.statusColumnHeading {
    width: 15%;
    background: var(--app-dark);
}

.actionColumnHeading {
    width: 15%;
    text-align: left;
    padding-left: 0.5rem;
    border-radius: 0 4px 4px 0;
    background: var(--app-dark);
}

.productRow {
    height: 7.35rem;
    border-top: 2px solid #f3f3f3;
    border-radius: 8px;
}

.productDetailsCell {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.bookImage {
    width: 3.875rem;
    height: 5.875;
    border-radius: 8px;
}

.bookName {
    font-size: 1rem;
    margin: 0;
    font-weight: var(--font-weight-semiBold);
}

.authorName {
    font-size: 0.875rem;
}

.orderByDate {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.75);
}

.centeredCell {
    text-align: center;
    background-color: white;
}

.leftAlignedCell {
    text-align: left;
    padding-left: 1.5rem;
    border-radius: 8px 0 0 8px;
    background-color: white;
}

.deliveryStatus {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.processed {
    background: rgb(237, 255, 248);
    color: rgb(23, 155, 28);
    border: 1px solid rgb(23, 155, 28);
}

.processing {
    background: rgb(236, 236, 236);
    color: rgb(163, 161, 161);
    border: 1px solid rgb(163, 161, 161);
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: rgb(163, 161, 161);
}

.reversed {
    background: rgb(250, 193, 193);
    color: rgb(197, 8, 30);
    border: 1px solid rgb(197, 8, 30);
}

.actionWrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.actionsCell {
    display: grid;
    gap: 0.3rem;
}

.visitOurStore {
    color: black;
    font-size: 0.875rem;
}

.trackOrders {
    color: rgb(242, 110, 20);
    font-size: 0.875rem;
}

.orderDetails {
    background-color: #ececec;
}

.itemsWrapper {
    padding: 1rem;
}

.itemSectionHeading {
    font-weight: var(--font-weight-medium);
}

.itemTable {
    width: 100%;
    border-spacing: 0 10px;
}