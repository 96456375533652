.main {
    display: grid;
    gap: 1rem;
}


.container {
    display: grid;
    gap: 1.5rem
}

.boldText {
    font-weight: var(--font-weight-bold);
}