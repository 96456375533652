.themeItem {
    width: 9.6rem;
    border: 2px solid transparent;
    border-radius: 0.75rem;
    padding: 0.5rem 0.5rem 0.65rem 0.5rem;
    background: white;
    color: var(--heading-dark-color);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.4s ease-in-out;
}

.themeItem:hover {
    transform: scale(1.05);
    background-color: rgb(232, 176, 62) !important;
}

.themeItem:hover  .themeImage {
    transform: scale(1.03);
} 

.themeItem.selected {
    background-color: rgb(232, 176, 62);
    color: white;
}

.themeImage {
    border-radius: 0.75rem;
    overflow: hidden;
    width: 9.5rem;
    height: 11rem;
    transition: transform 0.8s ease;
}

.themeName {
    text-align: center;
    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: var(--font-weight-semiBold);
    line-height: 1.35rem;
}

.aiLogo {
    position: absolute;
    top: 1rem;
    right: 0;
    background: var(--button-color-orange);
    width: 4rem;
    text-align: center;
    color: white;
    border-radius: 1rem;
}

.aiLogo>div {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7rem;
}

.aiLogo:hover>div {
    display: block;
}