.main {
  display: grid;
  gap: 2rem;
}

.container {
  width: 100%;
  /* to be removed */
  display: grid;
  gap: 3rem;
}

.orderTableContainer {
  width: 100%;
}

.orderTable {
  width: 100%;
  border-collapse: collapse;
}

.orderTableHeading {
  height: 3rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: var(--font-weight-semiBold);
  color: white;
}

.productColumnHeading {
  width: 35%;
  border-radius: 4px 0 0 4px;
  background: var(--app-dark);
  text-align: left;
  padding-left: 1.5rem;
}

.orderId {
  width: 8%;
  text-align: left;
  padding-left: 1.5rem;
  background: var(--app-dark);
  border-radius: 4px 0 0 4px;
}

.orderDate {
  width: 16%;
  background: var(--app-dark);
}

.quantity {
  width: 8%;
  background: var(--app-dark);
}

.quantityColumnHeading {
  width: 15%;
  background: var(--app-dark);
}

.priceColumnHeading {
  width: 13%;
  background: var(--app-dark);
}

.statusColumnHeading {
  width: 15%;
  background: var(--app-dark);
}

.actionColumnHeading {
  width: 15%;
  text-align: left;
  padding-left: 0.5rem;
  border-radius: 0 4px 4px 0;
  background: var(--app-dark);
}

.productRow {
  height: 7.35rem;
  border-top: 2px solid #f3f3f3;
  border-radius: 8px;
}

.productDetailsCell {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.bookImage {
  width: 3.875rem;
  height: 5.875;
  border-radius: 8px;
}

.bookName {
  font-size: 1rem;
  margin: 0;
  font-weight: var(--font-weight-semiBold);
}

.authorName {
  font-size: 0.875rem;
}

.orderByDate {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.75);
}

.centeredCell {
  text-align: center;
  background-color: white;
}

.leftAlignedCell {
  text-align: left;
  padding-left: 1.5rem;
  border-radius: 8px 0 0 8px;
  background-color: white;
}

.deliveryStatus {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivered {
  width: fit-content;
  font-size: 0.75rem;
  background: rgb(237, 255, 248);
  padding: 0.5rem;
  color: rgb(23, 155, 28);
  border: 1px solid rgb(23, 155, 28);
  border-radius: 4px;
}

.processing {
  width: fit-content;
  font-size: 0.75rem;
  background: rgb(236, 236, 236);
  padding: 0.5rem;
  color: rgb(163, 161, 161);
  border: 1px solid rgb(163, 161, 161);
  border-radius: 4px;
}

.inTransit {
  width: fit-content;
  font-size: 0.75rem;
  background: rgb(255, 237, 225);
  padding: 0.5rem;
  color: rgb(242, 110, 20);
  border: 1px solid rgb(242, 110, 20);
  border-radius: 4px;
}

.actionWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.actionsCell {
  display: grid;
  gap: 0.3rem;
}

.visitOurStore {
  color: black;
  font-size: 0.875rem;
}

.trackOrders {
  color: rgb(242, 110, 20);
  font-size: 0.875rem;
}

.orderDetails {
  background-color: #ececec;
}

.itemsWrapper {
  padding: 1rem;
}

.itemSectionHeading {
  font-weight: var(--font-weight-medium);
}

.itemTable {
  width: 100%;
  border-spacing: 0 10px;
}

.itemHeadingWrapper {
  height: 2.25rem;
  margin-bottom: 1rem;
}

.itemHeading {
  font-size: 0.875rem;
  font-weight: 500;
  background: white;
  height: 2rem;
  color: #707070;
}

.itemHeading:last-child {
  border-radius: 0 4px 4px 0;
}

.borderTopRightBottomLeft {
  border-radius: 0 8px 8px 0;
}

.chevronIconWrapper {
  transition: all 0.3s ease-in-out;
}

.chevronDown {
  rotate: 180deg;
}

@media screen and (min-width: 360px) and (max-width: 600px) {
  .leftAlignedCell {
    padding-left: 0rem;
    padding-right: 0.5rem;
  }

  .productDetailsCell {
    gap: 0.25rem;
  }

  .productColumnHeading {
    padding-left: 0rem;
    text-align: center;
  }

  .itemsWrapper {
    padding: 0.5rem;
  }

  .orderTableContainer {
    margin-left: -2.25rem;
  }

  .chevronIconWrapper {
    margin-right: 0.25rem;
  }

  .orderTableHeading>tr th {
    font-size: 0.875rem;
    padding: 0.125rem;
  }

  .centeredCell {
    font-size: 0.875rem;
  }

  .leftAlignedCell {
    font-size: 0.875rem;
  }

  .orderId {
    text-align: center;
    padding-left: 0rem;
  }

  .itemHeadingWrapper:last-child {
    padding: 0.5rem;
  }

  .itemHeading {
    padding-right: 0.5rem;
  }

  .orderId {
    width: 8%;
    text-align: left;
    padding-left: 1.5rem;
    background: var(--app-dark);
    border-radius: 4px 0 0 4px;
  }

  .orderDate,
  .quantity,
  .quantityColumnHeading,
  .priceColumnHeading,
  .statusColumnHeading,
  .actionColumnHeading {
    width: auto;
  }
}