.checkBox[type=checkbox] {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
}

.checkBox[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    left: 0;
    border: 1px solid gray;
    border-radius: 3px;
    background-color: white;
}

.checkBox[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 0.4rem;
    height: 0.85rem;
    border: solid var(--button-color-orange);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 12%;
    left: 35%;
}