.main {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    border-radius: .65rem;
    cursor: pointer;
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
    gap: 0;
}

.thumbnailWrapper {
    width: 100%;
    height: fit-content;
    border-radius: .5rem;
}

.thumbnailWrapper img {
    border-radius: .5rem;
    object-fit: contain;
    width: 100%;
}

@media (max-width: 360px) {
    .thumbnailWrapper {
        width: 100%;
    }

    .thumbnailWrapper img {
        object-fit: contain;
    }
}


.titleWrapper {
    width: 100%;
    padding: .25rem;
    margin-top: .75rem;
}

.title {
    font-size: 1rem;
    font-weight: 600;
}

.description {
    margin-top: 0.5rem;
    width: 100%;
    text-overflow: ellipsis;
    text-wrap: wrap
}


.eula {
    display: flex;
    justify-items: center;
}

.modelDialog :global(.MuiDialog-paperWidthSm) {
    max-width: 90vw;
}

.eulaContainer>div {
    height: 100%;
    width: 100%;
}