.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid;
    overflow: clip;
}

.mainContainer > img {
    width: 65%;
    height: 65%;
}