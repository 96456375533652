.main {
    width: 16.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #cfc7c7;
    cursor: pointer;
    overflow: hidden;
}

.buddingImageWrapper {
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.main:hover  .buddingImageWrapper img  {
    transform: scale(1.15);
} 

.buddingImageWrapper img {
    width: 100%;
    object-fit: contain;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    transition: transform ease-in-out .5s;
}

.buddingContentWrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
}

.buddingTitle {
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
    margin: 0;
    width: 100%;
}

.buddingPara {
    font-size: 0.875rem;
    width: 100%;
}

.blogTitleWrapper {
    height: unset;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.blogTitle {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: unset;
    padding-top: 1rem;

}

.readMoreContainer {
    padding: 0.5rem;
}