.main {
    color: var(--button-color-yellow);
    font-size: 0.75rem;
    font-weight: var(--font-weight-bold);
}

.main:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.disabled {
	pointer-events: none;
	opacity: 0.3;
	cursor: auto;
}