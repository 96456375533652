.mainContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-top: 3.2rem;
}

.heroImageContainer {
    width: 100%;
    position: relative;
}

.heroImageContainer img {
    width: 100%;
    object-fit: cover;
}

.heroImageContainer div:not(:last-child) {
    width: 100% !important;
    height: auto !important;
}

.heroImageContainer video {
    width: 100%;
    object-fit: cover;
}

.heroLogoImageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 16rem;
    border-radius: 30px;
    padding: 0.5rem 0;
    background-color: white;
}

.createButton {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 42vw;
    top: 38.75vw;
}

.createButton button {
    height: auto;
    border-radius: 0.75rem;
    border: 1px solid var(--landingPage-text);
    color: var(--selection-dark);
    background: #CFC5FF;
    padding: 1vw 2.5vw;
    font-size: max(2vw, 1rem);
    transition: all ease 0.5s;
}

.createMobileButton button {
    height: auto;
    border-radius: 0.75rem;
    border: 1px solid var(--landingPage-text);
    color: var(--selection-dark);
    background: #CFC5FF;
    padding: .5vw 2.5vw;
    font-size: max(2vw, .650rem);
    transition: all ease 0.5s;
}

.createMobileButton {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 42vw;
    top: 38.75vw;
}

.createButton button:hover,
.createButton button:focus,
.createMobileButton button:hover,
.createMobileButton button:focus {
    /* background: var(--selection-dark) !important; */
    transform: scale(1.02);
    background: #CFC5FF !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
        rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.heading {
    font-family: "New Walt Disney";
    color: white;
    font-size: 4.5rem;
    font-weight: normal;
    margin-top: 1rem;
}

.whoAreWeMain {
    background: linear-gradient(93deg,
            rgba(98, 42, 122, 1),
            rgba(111, 47, 135, 1),
            rgba(90, 41, 150, 1),
            rgba(72, 35, 163, 1),
            rgba(70, 34, 164, 1),
            rgba(52, 28, 176, 1),
            var(--heading-dark-color));
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 6.2rem 0;
    position: relative;
    top: -0.45rem;
}

.whoAreWeHeading {
    font-size: 2.375rem;
    font-weight: var(--font-weight-bold);
    color: white;
    text-align: center;
}

.editorChoiceHeading {
    font-size: 2.375em;
    font-weight: var(--font-weight-bold);
    color: white;
    text-align: center;
    margin-bottom: 3rem;
}

.carousel {
    width: 100%;
    display: flex;
    height: 30rem;
    align-items: center;
    margin-top: 2rem;
}

.carousel :global(.rec-dot) {
    background-color: white;
    height: 14px;
    width: 39px;
    border-radius: 25px;
    box-shadow: none;
    transition: all 0.8s ease-in-out;
}

.carousel :global(.rec-pagination) {
    margin: 0;
}

.bXxycX:hover {
    box-shadow: none;
}

.carousel :global(.rec-pagination) {
    margin-top: 3rem;
}

.carousel :global(.rec-item-wrapper) {
    height: 22rem;
    display: flex;
    align-items: center;
}

.carousel :global(.rec-dot_active) {
    background: linear-gradient(259deg,
            var(--button-color-orange),
            var(--button-color-yellow));
    height: 14px;
    width: 81px;
    border-radius: 25px;
    box-shadow: none;
}

.whoAreWeCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: rgb(98, 42, 122);
    border-radius: 25px;
    width: 65%;
    padding: 1rem;
    box-sizing: border-box;
    height: 18rem;
    transition: height 0.3s, margin-bottom 0.3s, transform 0.3s;
    gap: 1rem;
}

.whoAreWeCardActive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-radius: 25px;
    width: 65%;
    padding: 1rem;
    box-sizing: border-box;
    height: 18rem;
    box-shadow: 21px 25px 0px 5px rgba(255, 255, 255, 1);
    transform: translate(-2rem, -2rem);
    transition: height 1s, margin-bottom 1s, transform 1s, box-shadow 1s;
    gap: 1rem;
}

.whoAreWeCardImage {
    flex: 0.5 1;
    width: 15rem;
    height: 10rem;
    display: flex;
    border-radius: 38% 41% 39% 42% / 33% 56% 43% 41%;
    align-items: center;
    justify-content: center;
    object-fit: cover;
}

.whoAreWeCardImage>div {
    width: 10rem;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.whoAreWeCardImage img {
    width: 100%;
}

.whoAreWeCardTitle {
    flex: 0.5;
}

.whoAreWeCardTitle>div {
    font-size: 1.5rem;
    font-weight: bold;
}

.displayNone {
    display: none;
}

.ratedBooks {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5rem 0;
}

.ratedBooksHeader {
    font-size: 2.35rem;
    font-weight: var(--font-weight-bold);
    color: var(--heading-dark-color);
}

.bookList {
    margin-top: 4rem;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.exploreMoreBooks {
    margin-top: 3rem;
}

.demoVideoSection {
    width: 100%;
    background: #e9f4fd;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 2rem;
    box-sizing: border-box;
}

.editorChoiceMain {
    width: 100%;
    height: max-content;
    overflow: hidden;
    position: relative;
    margin-top: -0.4rem;
    background: linear-gradient(67deg,
            rgb(52, 28, 176),
            rgb(42, 22, 141),
            rgb(111, 47, 135));
    padding: min(3rem, 5vw);
    box-sizing: border-box;
}

.editorsChoiceWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, minmax(8rem, 1fr));
    gap: 1rem;
    overflow: hidden;
}

.editorsChoiceWrapper>a {
    overflow: hidden;
    display: flex;
    height: fit-content;
}

.editorsChoiceWrapper>a>img {
    width: 100%;
    object-fit: contain;
    border-radius: 1.25rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.35s ease-in-out;
}

.editorsChoiceWrapper img:hover {
    transform: scale(1.1);
}

.imageContainer {
    width: 100%;
    height: max-content;
    overflow: hidden;
    position: relative;
}

.editorsChoiceWrapper .editorsChoiceImageContainer {
    flex: 0.25;
    width: 100%;
    height: 100%;
}

.videoWrapper {
    width: 100%;
    max-width: 60rem;
    margin: auto;
    background: #003e95;
    border-radius: 15px;
    display: flex;
    padding: 3rem;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16 / 9;
}

.video {
    width: 100%;
    height: 100%;
}

.sectionHeading {
    width: 100%;
    text-align: center;
    font-size: 2.375rem;
    font-weight: var(--font-weight-bold);
    color: white;
}

.features {
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px black;
    background: linear-gradient(267deg,
            rgba(98, 42, 122, 1),
            rgba(111, 47, 135, 1),
            rgba(90, 41, 150, 1),
            rgba(72, 35, 163, 1),
            rgba(70, 34, 164, 1),
            rgba(52, 28, 176, 1),
            var(--heading-dark-color));
    color: white;
    position: relative;
    padding: 2rem;
    z-index: 10;
}

.featureItems {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    color: white;
}

.bookLifeHeading {
    color: white;
    font-size: 2.35rem;
    font-weight: var(--font-weight-bold);
}

.bookLifeMain {
    width: 100%;
    box-sizing: border-box;
    background: #ef8415;
    padding: 5rem;
    position: relative;
    display: flex;
}

.bookLifeHeadingWrapper {
    z-index: 1;
}

.bookLifeCardsContainer {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.booklifeBackgroundImageWrapper {
    left: 0rem;
    bottom: 0rem;
    position: absolute;
}

.booklifeBackgroundImage {
    width: 100%;
}

.getRecognizedAsAuthorMain {
    width: 100%;
    box-sizing: border-box;
    background: linear-gradient(67deg,
            rgb(52, 28, 176),
            rgb(42, 22, 141),
            rgb(111, 47, 135));
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.getRecognizedAsAuthorHeading {
    display: flex;
    justify-content: space-between;
}

.aloozaHeroImage {
    flex: 0.2;
    display: flex;
    align-items: center;
    width: 20rem;
}

.aloozaHeroImage img {
    width: 100%;
}

.getRecognizedAsAuthorTitle {
    flex: 0.5;
    display: flex;
    align-items: center;
    text-align: end;
    color: white;
}

.getRecognizedAsAuthorTitle>div {
    width: 100%;
    font-size: 2.375rem;
    font-weight: var(--font-weight-bold);
}

.getRecognizedAsAuthorContainer {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.b2bInfoContainer {
    width: 100%;
    padding: 5rem 0;
    background: #ffffff;
}

.noBooks {
    grid-column: span 5;
    text-align: center;
    padding-bottom: 1.5rem;
    color: #fff;
}

.b2bContentWrapper {
    width: 100%;
    max-width: 85rem;
    padding: 2rem;
    margin: auto;
    display: grid;
    gap: 2rem 2.5rem;
    box-sizing: border-box;
}

.b2bButton {
    color: var(--selection-dark);
    background: rgba(111, 47, 135, 0.1);
    border: 1px solid var(--selection-dark);
}

.b2bButton:hover {
    background: var(--app-dark) !important;
    color: #fff;
}

.b2bBannerImageWrapper {
    border-radius: 1rem;
    overflow: hidden;
    padding: 0.5rem;
    border: 2px solid var(--selection-dark);
    aspect-ratio: 3/4;
}

.b2bMarquee {
    height: 100%;
    width: 100%;
}

.b2bMarquee div {
    height: 100%;
}

.b2bMarqueeChild {
    aspect-ratio: 3/4;
    padding: .5rem;
    box-sizing: border-box;
}

.b2bBannerImageWrapper img {
    border-radius: 1rem;
    aspect-ratio: 3/4;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.b2bHeader {
    margin-bottom: 2.5rem;
}

.b2bHeader h1 {
    font-family: Poppins, sans-serif;
    font-size: 2.20rem;
    text-align: center;
    margin: 0;
    margin-bottom: 1.2rem;
}

.b2bHeader h2 {
    color: var(--selection-dark);
    font-size: 1.875rem;
    font-family: Poppins, sans-serif;
    margin-bottom: 1rem;
    font-weight: 500;
}

.b2bHeader p {
    font-size: 1.25rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
}

.b2bWork {
    margin-bottom: 1.5rem;
}

.b2bWork>h4 {
    color: var(--selection-dark);
    font-size: 1.875rem;
    font-family: Poppins, sans-serif;
    margin: 0;
    font-weight: 500;
}

.b2bWorkList li {
    font-size: 1.25rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
}

.seoBannerMain {
    width: 100%;
    background-color: #fff;
    margin: -.5rem 0;
}

.seoHeader {
    position: relative;
}

.seoCreateButtonWrapper {
    position: absolute;
    bottom: 1.5vw;
    right: 10vw;
}

.seoCreateButton {
    height: max-content;
    background: #fff;
    color: var(--text-light-color);
    padding: .25rem 1rem;
    font-size: max(2vw, .875rem);
}

.seoCreateButton:hover {
    background: var(--selection-dark) !important;
    color: #fff !important;
}

.seoImageContainer {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.seoImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: content;
}

.seoContent {
    width: 100%;
    max-width: 85rem;
    padding: 3rem 2rem;
    margin: auto;
    display: grid;
    gap: 3.5rem;
    box-sizing: border-box;
}

.seoContent div {
    font-size: 1.25rem;
    font-family: Poppins, sans-serif;

}

.testimonialsMain {
    width: 100%;
    background-color: #fff;
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
}

.testimonialsHeading {
    max-width: 44rem;
    text-align: center;
    margin: auto;
    padding: 1rem;
}

.testimonialsHeading h1 {
    font-size: min(23vw, 8rem);
    font-family: "Big Shoulders Text", serif;
    color: rgb(28, 16, 92);
}

.testimonialsContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
}

.testimonialsCarousalWrapper {
    height: 100%;
}

.testimonialsCarousal {
    display: flex;
    flex-direction: column;
    animation: scroll 35s linear infinite;
}

.testimonialsCarousal * {
    box-sizing: border-box;
}

.testimonialCardWrapper {
    border-radius: 1rem;
    color: #fff;
    padding: 1rem;
    flex-grow: 1;
    width: 100%;
    height: fit-content;
    display: flex;
    min-height: 10rem;
}

.testimonialCard {
    background: rgb(113, 72, 181);
    background: linear-gradient(151deg, rgba(113, 72, 181, 1) 0%, rgba(129, 96, 219, 1) 48%, rgba(113, 93, 211, 1) 100%);
    width: max-content;
    height: max-content;
    max-width: 24rem;
    border-radius: 1rem;
    padding: 1.25rem ;
}

.testimonialCardHeader {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.testimonialsBtn {
    border: 1px solid rgb(28, 16, 92);
    color: rgb(28, 16, 92);
    position: relative;
    z-index: 1;
}

.testimonialCardContent {
    display: flex;
    gap: 1rem;
}

.testimonialCardAuthor {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    border: 5px solid #ccc;
    aspect-ratio: 1/1;
}

.testimonialCardAuthor img {
    width: 100%;
    border-radius: 100%;
    object-fit: contain;
}

.testimonialCardAuthorDetails > h4 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: .5rem;
}

.testimonialCardAuthorDetails > hr {
    background: #fff;
}

.testimonialCardAuthorDetails > p {
    font-size: 1rem;
    font-weight: 600;
}

@keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-50%);
    }
  }

@media (min-width: 1024px) {
    .seoContent {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .seoImageContainer {
        aspect-ratio: unset;
    }

    .b2bContentWrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .b2bBannerImageWrapper {
        grid-column: span 1 / span 1;
    }

    .b2bContent {
        grid-column: span 2 / span 2;
    }
}

@keyframes borderAnimation {
    from {
        background-position: 0 0, -20px 0, 100% -20px, 0 100%;
    }

    to {
        background-position: 0 -20px, 0 0, 100% 0, -20px 100%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .whoAreWeCardImage img {
        width: 70%;
    }

    .whoAreWeCardImage>div {
        width: 10rem;
    }

    .whoAreWeCardImage {
        height: 8rem;
    }

    .getRecognizedAsAuthorTitle>div {
        font-size: 2rem;
    }

    .aloozaHeroImage {
        flex: 0.2;
    }

    .whoAreWeCard {
        height: 15rem;
    }

    .whoAreWeCardActive {
        height: 15rem;
    }

    .whoAreWeCardTitle>div {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
    .whoAreWeCardImage>div {
        width: 10rem;
    }

    .whoAreWeCardImage img {
        width: 70%;
    }

    .whoAreWeCardImage {
        height: 8rem;
    }

    .whoAreWeCardTitle {
        font-size: 1rem;
    }

    .getRecognizedAsAuthorTitle>div {
        font-size: 1.875rem;
    }

    .aloozaHeroImage {
        flex: 0.3;
    }

    .whoAreWeCard {
        height: 14rem;
    }

    .whoAreWeCardActive {
        height: 14rem;
    }

    .whoAreWeCardTitle>div {
        font-size: 1rem;
    }
}

@media screen and (min-width: 820px) and (max-width: 1000px) {
    .whoAreWeCardImage img {
        width: 70%;
    }

    .whoAreWeCardImage>div {
        width: 10rem;
    }

    .whoAreWeCardImage {
        height: 8rem;
    }

    .whoAreWeCardTitle>div {
        font-size: 1rem;
    }

    .heroLogoImageContainer {
        width: 14rem;
        padding: 0.1rem 0;
    }

    .clickNPublish {
        width: 50%;
    }

    .heroContent {
        padding: 4rem 1.875rem;
    }

    .bookLifeMain {
        padding: 1rem;
        flex-direction: column;
    }

    .booklifeBackgroundImageWrapper {
        width: 100%;
    }

    .booklifeBackgroundImage {
        width: 20%;
    }

    .bookLifeHeading {
        text-align: center;
    }

    .getRecognizedAsAuthorTitle>div {
        font-size: 1.75rem;
    }

    .aloozaHeroImage {
        flex: 0.3;
    }

    .whoAreWeCard {
        height: 12rem;
    }

    .whoAreWeCardActive {
        height: 12rem;
    }
}

@media (max-width: 819px) {
    /* .createButton {
        top: 37.5vw;
    } */

    .b2bBannerImageWrapper {
        grid-column: span 3 / span 3;
    }

    .b2bContent {
        grid-column: span 3 / span 3;
    }

    .editorsChoiceWrapper {
        grid-template-columns: repeat(3, minmax(8rem, 1fr));
    }

    .editorChoiceMain {
        font-size: 12px;
    }

    .whoAreWeCardImage>div {
        width: 7rem;
    }

    .whoAreWeCardImage {
        width: auto;
    }

    .whoAreWeCardImage img {
        width: 60%;
    }

    .whoAreWeCardTitle>div {
        font-size: 0.875rem;
        text-align: center;
    }

    .whoAreWeCard {
        flex-direction: column;
        row-gap: 1rem;
    }

    .whoAreWeCardActive {
        flex-direction: column;
        row-gap: 1rem;
    }

    .heroContent {
        padding: 3rem 1.5rem;
    }

    .heroLogoImageContainer {
        width: 12rem;
        padding: 0.2rem 0;
    }

    .heroContent {
        padding: 2.375rem 0.875rem;
    }

    .clickNPublish {
        width: 40%;
    }

    .videoWrapper {
        padding: 2rem;
    }

    .bookLifeMain {
        padding: 1rem;
        flex-direction: column;
    }

    .booklifeBackgroundImageWrapper {
        width: 100%;
    }

    .booklifeBackgroundImage {
        width: 20%;
    }

    .bookLifeHeading {
        text-align: center;
    }

    .getRecognizedAsAuthorTitle>div {
        font-size: 1.75rem;
    }

    .aloozaHeroImage {
        flex: 0.3;
    }

    .whoAreWeCard {
        height: 14rem;
    }

    .whoAreWeCardActive {
        height: 14rem;
    }
}

@media (max-width: 480px) {
    /* .createButton {
        top: 37.5vw;
    } */

    .editorChoiceMain {
        font-size: 12px;
    }

    .whoAreWeCardImage>div {
        width: 7rem;
    }

    .heroContent {
        padding: 2.125rem 0.875rem;
    }

    .heroLogoImageContainer {
        width: 8rem;
        padding: 0.1rem 0;
    }

    .clickNPublish {
        width: 40%;
    }

    .whoAreWeCardImage {
        width: auto;
    }

    .whoAreWeCardImage img {
        width: 60%;
    }

    .whoAreWeCardTitle>div {
        font-size: 0.875rem;
        width: 7rem;
        text-align: center;
    }

    .whoAreWeCard {
        flex-direction: column;
        row-gap: 1rem;
        height: 15rem;
    }

    .whoAreWeCardActive {
        flex-direction: column;
        row-gap: 1rem;
        height: 15rem;
    }

    .videoWrapper {
        padding: 1rem;
    }

    .bookLifeMain {
        padding: 1rem;
        flex-direction: column;
    }

    .booklifeBackgroundImageWrapper {
        display: none;
    }

    .booklifeBackgroundImage {
        width: 20%;
    }

    .bookLifeHeading {
        text-align: center;
        font-size: 1.375rem;
    }

    .getRecognizedAsAuthorTitle>div {
        font-size: 1.625rem;
        text-align: initial;
    }

    .getRecognizedAsAuthorContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .aloozaHeroImage {
        flex: 0.3;
    }

    .bookLifeCardsContainer {
        margin-top: 1rem;
    }

    .sectionHeading {
        font-size: 1.375rem;
    }

    .whoAreWeHeading {
        font-size: 1.375rem;
    }

    .editorChoiceHeading {
        font-size: 1.375rem;
    }

    .whoAreWeMain {
        padding: 1rem 0;
    }

    .ratedBooksHeader {
        font-size: 1.375rem;
    }

    .demoVideoSection {
        padding: 2rem;
    }

    .carousel :global(.rec-item-wrapper) {
        height: 250px;
        display: flex;
        align-items: center;
    }

    .carousel :global(.rec-dot) {
        height: 10px;
    }

    .whoAreWeCardActive {
        box-shadow: 8px 8px 0px 5px rgba(255, 255, 255, 1);
        transform: translate(-2rem, -2rem);
        transition: height 1s, margin-bottom 1s, transform 1s, box-shadow 1s;
    }

    .featureItems {
        gap: 1rem;
    }

    .carousel {
        height: 25rem;
        margin-top: 0rem;
    }

    .carousel :global(.rec-pagination) {
        margin-top: 0rem;
    }
}

.posterImage {
    align-self: center;
    width: 34%;
    height: 90%;
}

.walletInfoContainer {
    position: fixed;
    background-color: #000a;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.walletInfo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.walletInfo div {
    position: absolute;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    right: 0;
}