.main {
    display: flex;
    flex-direction: column;
    width: max-content;
    position: relative;
    height: 100%;
    align-items: center;
    cursor: pointer;
}

.header {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;


}

.header>span {
    font-size: 0.875rem;
    font-weight: var(--font-weight-medium);
    color: rgb(76, 38, 100);
}

.dropdownImage {
    width: 0.75rem;
    height: 0.75rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0.25rem;
    margin-left: 0.4rem;
}

.header:active {
    color: gray;
}

.children {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 2.1rem;
    z-index: 100;
    border-radius: 8px;
    right: 0;
    background-color: var(--panel-semi-dark-background);
    box-shadow: 0px 0px 30px #0008;
    overflow: hidden;
}

.count {
    transform: scale(.75);
    position: absolute;
    right: -0.4rem;
    top: -0.8rem;
    font-size: 1rem;
    font-weight: var(--font-weight-bold);
}


.bell {
    animation: ring 4s .7s ease-in-out;
    transform-origin: 50% 4px;
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }

    1% {
        transform: rotate(30deg);
    }

    3% {
        transform: rotate(-28deg);
    }

    5% {
        transform: rotate(34deg);
    }

    7% {
        transform: rotate(-32deg);
    }

    9% {
        transform: rotate(30deg);
    }

    11% {
        transform: rotate(-28deg);
    }

    13% {
        transform: rotate(26deg);
    }

    15% {
        transform: rotate(-24deg);
    }

    17% {
        transform: rotate(22deg);
    }

    19% {
        transform: rotate(-20deg);
    }

    21% {
        transform: rotate(18deg);
    }

    23% {
        transform: rotate(-16deg);
    }

    25% {
        transform: rotate(14deg);
    }

    27% {
        transform: rotate(-12deg);
    }

    29% {
        transform: rotate(10deg);
    }

    31% {
        transform: rotate(-8deg);
    }

    33% {
        transform: rotate(6deg);
    }

    35% {
        transform: rotate(-4deg);
    }

    37% {
        transform: rotate(2deg);
    }

    39% {
        transform: rotate(-1deg);
    }

    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(0);
    }
}

@media screen and (max-width: 600px) {
    .count {
        right: -0.5rem;
        top: -1rem;
    }
}