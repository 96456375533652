.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--app-white);
  padding: 1rem 1.5rem;
  gap: 0.5rem;
}

.btn {
  width: 3.1rem;
  height: 3.1rem;
  padding: 0.5rem;
  border: 1px solid #E8E9EC;
  border-radius: 4px;
  text-align: center;
  color: #4D4F5C;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}

.btn:disabled img{
  pointer-events: none;
  opacity: 0.4;
}

.btn:disabled path {
  stroke: var(--app-grey);
}

.pagination ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
}

.pagination ul li {
  list-style: none;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
  width: 2em;
  height: 2rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination ul li.numb {
  border: 1px solid #E8E9EC;
  border-radius: 4px;
  color: #4D4F5C;
}

.pagination ul li.dots {
  cursor: default;
  color: var(--app-grey);
}

.pagination li.active,
.pagination ul li.numb:hover,
.pagination ul li:first-child:hover,
.pagination ul li:last-child:hover {
  color: #fff !important;
  background: var(--button-color-orange) !important;
}