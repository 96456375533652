.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.bookImageWrapper {
    width: fit-content;
}

.bookImage {
    width: 4.25rem;
    border-radius: 4px;
}

.bookCard {
    padding: 1.3rem 1rem;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    display: flex;
    gap: 0.75rem;
}

.achievementBookCardContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.bookDetailsWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.bookTitle {
    margin: 0;
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
}

.publishDate {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
}

.shareBookButtonWrapper {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.sharingContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.5rem;
}

.shareImage {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    border: 1.5px solid rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--selection-dark);
}

.shareIcon {
    width: 43%;
}

.downloadIcon {
    width: 100%;
}

.linkWrapper {
    display: flex;
    gap: 0.5rem;
}

.linkButtonWrapper {
    display: flex;
    flex-direction: column;
}

.modalHeading {
    font-size: 1.5rem;
    font-weight: var(--font-weight-semiBold);
    margin: 0;
    color: var(--heading-dark-color);
}

.dialogueContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
}

.dialogue {
    display: grid;
    gap: 1.5rem;
    padding: 1.5rem;
}

.bookDisplayCard {
    width: 20rem;
    height: 20rem;
    display: flex;
    align-items: center;
    background: var(--button-color-orange);
    justify-content: center;
    border-radius: 1.5rem;
}

.bookDisplayCard img {
    width: 88%;
    border-radius: 1rem;
    border: 1px solid black;
}

.sharingLinks {
    display: flex;
    gap: 1rem;
}

.socialLinks {
    display: flex;
    gap: 0.5rem;
    text-decoration: none;
    color: black;
}

.hoverUnderlineAnimation {
    display: inline-block;
    position: relative;
    color: black;
}

.hoverUnderlineAnimation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: black;
    transition: transform 0.25s ease-out;
}

.hoverUnderlineAnimation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.socialMediaIcon {
    width: 1.5rem;
}

.link {
    border: 1px solid var(--selection-dark);
    padding: 0.2rem;
    border-radius: 4px;
    width: 15rem;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    text-overflow: ellipsis;

}

.modalDialogue :global(.MuiDialog-paperWidthSm) {
    max-width: 90vw;
}

.copyLinkBtn {
    background-color: transparent;
    border: none;
    outline: none;
    width: 2.5rem;
    height: 2.5rem;
    color: var(--button-color-yellow);
}

.copyLinkBtn:active {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.copyLinkBtn img {
    width: 100%;
    height: 100%;
}

.copied {
    align-self: center;
    display: flex;
    align-items: center;
    width: 2.5rem;
    text-align: center;
    height: 2.5rem;
    color: var(--button-color-yellow);
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
}

@media screen and (max-width: 600px) {
    .container {
        grid-template-columns: 1fr;
    }
}