.fabric-textbox {
  resize: none;
  overflow: hidden;
  white-space: break-spaces;
  background: transparent;
  border: none;
  outline: none;
}

.fabric-textbox::placeholder {
  color: #444;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #bbbbbb;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

.ks-input-suggestions {
  background-color: #f0f0f0;
  color: #525252;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  overflow: auto;
}

.ks-input-suggestions .suggestion-list {
  padding: 5px;
  padding-top: 15px;
}

.ks-input-suggestions .suggestion-list div {
  padding: 5px;
  padding-left: 10px;
}

.ks-input-suggestions .suggestion-list .suggestion-div.active {
  background-color: #8cbeff;
  border-radius: 5px;
  color: white;
}

.ks-input-suggestions .suggestion-list .suggestion-div:hover {
  background-color: #b6d5ff;
  color: white;
  cursor: pointer;
}

.ks-input-suggestions .suggestion-input {
  background-color: transparent;
  border: 0px;
}

.ks-input-suggestions .suggestion-input:focus {
  outline-width: 0;
}

.ks-input-suggestions .loading-box {
  text-align: center;
  height: 20px;
  position: absolute;
  right: 10px;
  left: 10px;
  top: -5px;
}

.hidden {
  display: none;
}

.mainCanvasWrapper {
  overflow: auto;
  width: 100%;
  height: 100%;
  touch-action: pan-x pan-y pinch-zoom;
}

.textAreaError {
  position: absolute;
  background-color: #f005;
  border-bottom: 2px solid red;
}

.dropdown {
  position: absolute;
  top: calc(100% + 5px);
  background-color: #fff;
  font-size: 0.6rem;
  padding: 5px;
  z-index: 99;
}

.dropdown .type {
  text-wrap: nowrap;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.dropdown ul {
  margin: 0px;
}

/* .spinner {
    position: absolute;
    background-color: #8cbeff;
  } */

.spinner {
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.spinner div {
  width: 20px !important;
  height: 10px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle closest-side, #474bff 90%, #0000) 0 0/33% 100% no-repeat;
  animation: dots-9e862z 1s steps(3) infinite;
}

@keyframes dots-9e862z {
  to {
    background-position: 150% 0;
  }
}

/* .spinner {
  width: 50.4px;
  height: 44.8px;
  --c: linear-gradient(#474bff 0 0);
  background: var(--c) 0% 25%,
    var(--c) 25% 50%,
    var(--c) 50% 75%,
    var(--c) 75% 75%,
    var(--c) 100% 75%;
  background-size: 10.1px 100%;
  background-repeat: no-repeat;
  animation: bars-t0lx83md 1s infinite linear;
}

@keyframes bars-t0lx83md {
  33% {
    background-size: 10.1px 10%, 10.1px 100%, 10.1px 100%;
  }

  50% {
    background-size: 10.1px 100%, 10.1px 10%, 10.1px 100%;
  }

  66% {
    background-size: 10.1px 100%, 10.1px 100%, 10.1px 10%;
  }
} */