.primaryTextArea {
  background-color: var(--input-background-color);
  width: 100%;
  resize: none;
  border: none;
  padding: 0.875rem;
  max-width: 100%;
  font-family: inherit;
  box-sizing: border-box;
}

.primaryTextArea:focus-visible {
  outline: none;
}
