.itemContainer {
    background: #014094;
    width: 13.5rem;
    height: 25.25rem;
    box-sizing: border-box;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: min(0.8rem, 2vw);
    font-family: Poppins;
    gap: 0.5rem;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.itemContainer:hover {
    transform: scale(1.05);
}

.itemCover {
    width: 100%;
    border-radius: 1rem;
    border: 1px solid #C0C0C0;
}

.itemTitle {
    font-size: 0.875rem;
    font-weight: var(--font-weight-bold);
    margin-top: 1.25rem;
}

.itemAuthor {
    font-size: 0.6875rem;
    text-align: start;
    color: white;
}

.starRatingAndPrice {
    display: flex;
    justify-content: space-between;
}

.itemPrice {
    font-size: 1rem;
    font-weight: var(--font-weight-bold);
    color: var(--selected-theme-color)
}

.ViewsAndLike {
    position: absolute;
    display: flex;
    background: white;
    padding: 0.5rem;
    width: 7.5rem;
    border-radius: 25px;
    justify-content: space-around;
    bottom: 4.3rem;
    left: 20%;
}

.view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.2rem;
}

.verticalLine {
    width: 1.7px;
    background-color: black;
}

.disableBookTheme {
    display: none;
}

.bookTheme {
    position: absolute;
    display: flex;
    background: #EA361A;
    width: 4rem;
    height: 2rem;
    align-items: center;
    border-radius: 1rem;
    justify-content: space-around;
    top: 1.6rem;
    right: -1rem;
    padding: 0 1rem;
}

.bookTheme span {
    font-size: 0.7rem;
    font-weight: var(--font-weight-semiBold);
    color: white;
}

.like {
    display: flex;
    align-items: center;
    justify-content: center
}

.view svg,
.like svg {
    margin-right: 0.2rem;
}


.ratingsLikesViews {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.ratingsLikesViewsTitles {
    font-size: 1rem;
    text-align: center;
    opacity: 0.8;
}

.ratingsLikesViewsContent {
    text-align: center;
    color: white;
}

.starImage {
    margin-left: 0.5rem;
    color: var(--selected-theme-color);
}

.heartImage {
    margin-left: 0.5rem;
    color: red;
}

.eyeImage {
    margin-left: 0.5rem;
    color: black;
}

.ratingsLikesViewsWrapper {
    width: 85%;
    align-self: center;
}

@media screen and (min-width: 360px) and (max-width: 480px) {
    .itemContainer {
        width: 12.5rem;
        height: auto;
    }
}

@media screen and (min-width: 481px) and (max-width: 820px) {
    .itemContainer {
        width: 10.5rem;
        height: auto;
    }
}