.main {
    display: flex;
    height: calc(100% - 3rem);
}

.image {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}

.goBack {
    display: flex;
    width: 8%;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 3rem;
}

.goBack span{
    font-size: 4rem;
    color: rgb(63, 81, 181);
}

.goBack span:hover{
    color: rgb(53, 68, 151)
}

.download {
    display: flex;
    width: 12%;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 3rem;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
}