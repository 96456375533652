.checkBox[type=checkbox] {
    position: relative;
    width: 2rem;
    height: 2rem;
}

.checkBox[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0;
    left: 0;
    border: 1px solid gray;
    border-radius: 3px;
    background-color: white;
}

.checkBox[type=checkbox]::after {
    content: url(../../assets/cart-icon.svg);
    display: block;
    width: 3px;
    height: 5px;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 18%;
    left: 27%;
}

.checkBox[type=checkbox]:checked::before {
    background-color: var(--selection-dark);
}

.checkBox[type=checkbox]:checked:after {
    content: url(../../assets/cart-icon-white.svg);
}