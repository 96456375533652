.main {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.form-field {
    background-color: var(--input-background-color);
    color: black;
    border: none;
    border-radius: 25px;
    padding-left: 1rem;
    width: 100%;
    height: 2.25rem;
}

.form-field:focus {
   outline: none;
}  

.form-field::placeholder{
    color: black;
}