.mainContainer {
    width: 100%;
    height: calc(100% - 3.2rem);
    background-image: url('../../assets/images/signin-signup-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 3.2rem;
    position: relative;
}

.mainBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    font-size: 1rem;
    background-color: var(--panel-semi-dark-background);
    border-radius: 24px;
}

.logoImage {
    display: grid;
    justify-items: center;
    margin-bottom: 1.5rem;
}

.bulbImage {
    width: 2.224rem;
    height: 3.037rem;
}

.tagline {
    width: 7.5rem;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
    padding: 2rem 2rem 4rem 2rem;
}

.heading {
    width: 100%;
}

.heading h2 {
    text-align: center;
    margin: 0;
    color: var(--heading-dark-color);
    font-size: 1.75rem;
    font-weight: var(--font-weight-bold);
}

.heading p {
    text-align: center;
    margin: 0;
    color: gray;
    font-size: 0.75rem;
    font-weight: var(--font-weight-regular);
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 0.75rem;
    margin-top: 1.5rem;
}

.forgotPassword {
    padding-left: 0.75rem;
}

.signup {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.75rem;
    font-weight: var(--font-weight-semiBold);
}

.textBtn {
    min-width: 4rem;
    color: var(--btn-primary-color);
    background-color: transparent;
    border: none;
    outline: none;
}

.actions {
    text-align: center;
    display: flex;
    justify-content: center;
}

.schoolSignup {
    margin-top: 1rem;
    text-align: center;
    font-size: .875rem;
    font-weight: var(--font-weight-semiBold);
}