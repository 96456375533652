.main {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0rem 0 5rem 0;
        margin-top: 3.2rem;
        background-color: var(--background-color);
}
    
.heroSection{
    background-repeat: no-repeat;
    /* background-position: center center; */
    background-size: cover;
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    height: 721px;
    position: relative;
}

.heroContent{
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    display: flex;
    flex-direction: column;
}

.companyName{
    margin-top: 81px;
    color: white;
}

.heroTitleContainer{
    background: rgba(255,255,255,0);
    top: 170px;
    z-index: 45;
    height: auto;
}

.heroTitle{
    font-size: 4rem;
    line-height: 85px;
    letter-spacing: -5px;
    /* color: rgb(21, 47, 46); */
    color: white;
    font-style: normal;
    font-weight: 600;
    word-spacing: 0.3rem;

}

.heroDescription{
    font-size: 20px;
    line-height: 28px;
    /* color: rgb(21, 47, 46); */
    color:  white;
    font-style: normal;
    word-spacing: 0.5rem;
    font-weight: bold;
}


.registerTopSection, .benefitsSection, .classRoomTalesSection, .moreInfoSection, .registerNowSection{  
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
    padding: 2rem 0rem;
}

.benefitsSection{
    background: rgba(255,255,255,1);
}

.moreInfoSection{
    background: rgba(255,255,255,1);
}


.registerTopContent, .benefitsContent, .classRoomContent, .moreInfoContent, .registerNowContent{
    margin-left: auto;
    margin-right: auto;
    padding: 0 3rem;
    position: relative;
}

.registerTopContent{
    display: flex;
    gap : 8rem;
    flex-wrap: wrap;
    justify-content: center;
}

.registerTopLeftPara{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.registerTopLeftPara h1{
        text-align: center;
}
.registerTopLeftPara span{
        font-weight: bold;
}


.registerForm{
    display: block;
    background: rgba(21,47,46,1);
    border-style: none;
    border-radius: 0px;
    width: 350px;
    height: min-content;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.registerFormTitle{
    font-size: 32px;
    line-height: 1.2em;
    color: rgb(255, 255, 255);
    font-style: normal;
    font-weight: 600;
    word-spacing: 0.3rem;
    text-align: center;
}

.formInputBox input{
    height: 3rem;
}

.formInputBox select{
    height: 3rem;
}

.formInputBox button{
    height: 3rem;
    width: 100%;
}

.benefitsContent h1{
    text-align: center;
}

.benefitsList{
    display: flex;
    margin: 5rem 0;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 5rem;
    justify-content: center;
}

.benefitsList>div{
  width: 300px;
  display: flex;
  flex-direction: column;
  
}

.benefitsList>div>h3{
    margin-bottom: 1rem;
    text-align: center;
}

.benefitsList>div p{
    text-align: justify;
}

.benefitsList span{
    font-weight: bold;
    color: var(--button-color-orange);
}

.benefitsIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.benefitsIcon img{
    height: 150px;
    width: 150px;
}

.classRoomContent h1{
    text-align: center;
}


.classRoomContent h4{
    text-align: center;
    color: orange;
}

.talesListContainer{
    display: flex;
    margin: 5rem 0;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 5rem;
    justify-content: center;

}

.talesCard{
    display: flex;
    flex-direction: column;
    width: 300px;
}

.talesImageContainer, .talesImageContainer img{
    border-style: none;
    border-radius: 0px;
    width: 300px;
    height: 169px;
}
.talesImageContainer img{
   object-fit: cover;
}

.talesContent h4{
    color: black;
    text-align: center;
}

.talesContent p{
    text-align: justify;
}

.moreInfoContent{
     display: flex;
    gap: 2.5rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 5rem 3rem;
}

.moreInfoContent img{
    width: 100%;
}
.moreInfoText{
    text-align: justify;
}


.registerNowContent h1, .registerNowContent h3{
    text-align: center;
}

.registerNowForm{
    padding:3rem 0rem;
}


@media screen  and  (min-width: 820px){
    .heroContent{
        width: 940px;
        gap: 2.5rem;
        margin-left: 10rem;
    }

   
    .heroTitleContainer{
        width: 657px;
    }

    .heroDescritionContainer{
         width: 464px;

    }
    .registerTopContent{
        flex-wrap: nowrap;
        width: 940px;
        padding: unset;
    }
    .registerTopLeftPara{
         width: 50%;
    }
    .benefitsContent, .classRoomContent {
        width: 1000px;
        padding: unset;
    }
    .registerNowContent {
        width: 940px;
        padding: unset;
    }
    .benefitsList{
        justify-content: space-between;
    }

    .talesListContainer{
        justify-content: space-between;
    }
    .moreInfoContent{
        width: 940px;
        flex-wrap: nowrap;
        gap: 5rem;

    }
    .moreInfoContent img{
        width: unset;
    }

    .registerNowForm{
        padding:3rem 8rem;
    }

    .registerTopLeftPara h1{
        text-align: left;
}
    
}
