.main {
    width: 100%;
    height: 100%;
}

.videoWrapper {
    width: 100%;
    position: relative;
    padding-bottom: 50%;
    margin: auto;
}

.videoWrapper>iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.content {
    margin-top: 2rem;
}

.title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

@media (min-width: 576px) {
    .videoWrapper {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .videoWrapper {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .videoWrapper {
        max-width: 960px;
        padding-bottom: 40%;
    }
}

@media (min-width: 1200px) {
    .videoWrapper {
        max-width: 1000px;
    }
}