.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container {
    display: grid;
    gap: 2rem;

}

.earningWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(15.75rem, 34vw), 1fr));
    gap: 1rem;
}

.totalEarnings,
.transferredAmount,
.remainingAmounts {
    height: 8.5rem;
    border-radius: 8px;
}

.totalEarnings {
    border: 1px solid var(--app-dark);
}

.transferredAmount {
    border: 1px solid rgb(242, 110, 20);
}

.remainingAmounts {
    border: 1px solid rgb(35, 19, 117);
}

.transferredAmountHeading,
.remainingAmountHeading,
.totalEarningHeading {
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 30%;
    color: white;
    padding: 0.75rem;
}

.totalEarningHeading {
    background-color: var(--app-dark);
    border-radius: 8px;
}

.transferredAmountHeading {
    background-color: rgb(242, 110, 20);
    border-radius: 8px;
}

.remainingAmountHeading {
    background-color: rgb(35, 19, 117);
    border-radius: 8px;
}


.amountWrapper {
    height: 70%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0.75rem;
}

.amount {
    font-size: 1.25rem;
    font-weight: var(--font-weight-semiBold);
}

.disclaimerWrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    gap: 1rem;
    background: rgb(232, 228, 251);
    border-radius: 4px;
}

.disclaimerWrapper svg {
    color: var(--app-dark);
    font-size: 1.5rem;
}

.earningTable {
    width: 100%;
    border-collapse: collapse;
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
}

.headingRow {
    border-radius: 8px 0 0 8px;
}

.earningTableHeading {
    height: 2.5rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
    color: white;
}

.bookTitleHeadingCell {
    text-align: left;
    width: 25%;
    padding-left: 1.5rem;
    background: var(--app-dark);
    border-radius: 8px 0 0 8px;
}

.dateHeadingCell,
.copiesSold {
    width: 25%;
    background: var(--app-dark);
}

.earningHeadingCell {
    width: 15%;
    background: var(--app-dark);
    border-radius: 0 8px 8px 0;
}

.bookNameCell {
    padding-left: 1.5rem;
}

.productRow .bookDate {
    text-align: center;
}

.bookCopies {
    text-align: center;
}

.bookPrice {
    text-align: center;
}

.productRow {
    height: 5rem;
    border-top: 2px solid #f3f3f3;
}

.claimContainer {
    display: grid;
    gap: 1.25rem;
    padding: 2.25rem;
    border: 1px solid var(--selection-dark);
    border-radius: 8px;
}

.checkBoxAndButtonsWrapper {
    display: grid;
    gap: 2rem
}

.checkBoxWrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.buttonsWrapper {
    display: flex;
    gap: 2rem;
}

.content {
    margin: 1.25rem 0;
}