.main {
    display: flex;
    flex-direction: column;
    background: var(--background-color);
    padding: 2rem 0 5rem 0;
    align-items: center;
    justify-content: center;
    margin-top: 3.2rem;
    gap: 2rem;
}

.backButtonWrapper {
    width: 85%;
    display: flex;
    align-items: start;
}

.container {
    width: 85%;
    display: flex;
}


.contactDetails {
    flex: 0.45 1;
    background: linear-gradient(146deg, #6A3089, #231375);
    padding: 3rem;
    border-radius: 8px;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.headingText {
    font-size: 2.375rem;
    font-weight: var(--font-weight-bold);
    margin: 0;
    color: white;
}

.formSection {
    flex: 0.55;
    background-color: white;
    border-radius: 0 8px 8px 0;
    padding: 3rem;
    display: grid;
    align-content: center;
    gap: 1rem;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 0.75rem;
}

.bulb {
    height: 5rem;
}

.tagline {
    width: 7.5rem;
    height: 1.2rem;
}

.googleMapWrapper {
    width: 20rem;
    height: 15rem;
}

.contactWrapper {
    font-size: 1rem;
    color: white;
    display: grid;
    gap: 1.5rem;
}

.contactUsLabel {
    font-size: 1rem;
    color: white;
}

.phone,
.mail,
.address {
    text-decoration: none;
    color: #FFD536;
}

.formWrapper {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    padding: 3.3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.heading {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    margin: 0;
    color: var(--heading-dark-color)
}

.phoneAndEmailWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.input {
    width: 100%;
    background-color: var(--input-background-color);
    color: black;
    border: none;
    border-radius: .5rem;
    padding-left: 1rem;
    height: 3rem;
    box-sizing: border-box;
}

.input::placeholder {
    color: black;
}

.input:focus {
    outline: none;
}

.messageContainer {
    background-color: var(--input-background-color);
    color: black;
    font-family: "Poppins";
    border: none;
    border-radius: .5rem;
    resize: none;
    height: 6.5rem;
    padding: 1rem;
    box-sizing: border-box;
}

.messageContainer::placeholder {
    color: black;
}

.messageContainer:focus {
    outline: none;
}

.formWrapper .success {
    color: green;
}

.formWrapper .error {
    color: red;
}

.nameInput {
    width: 100% !important;
}

.map {
    width: 20rem;
    height: 15rem;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
    .input {
        width: 100%;
    }

    .formWrapper {
        padding: 1rem;
    }

}

@media screen and (min-width: 481px) and (max-width: 1000px) {
    .container {
        flex-direction: column;
        row-gap: 0.5rem;
    }

    .input {
        width: 100%;
    }
}

@media screen and (min-width: 360px) and (max-width: 480px) {
    .container {
        flex-direction: column;
    }

    .input {
        width: 100%;
    }

    .formWrapper {
        padding: 1rem;
    }

    .formSection {
        padding: 2rem;
        border-radius: 8px;
        margin-top: 1rem;
    }

    .contactDetails {
        padding: 2rem;
    }

    .googleMapWrapper {
        margin-bottom: 1.5rem;
    }
}