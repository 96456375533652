.mainContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row dense;
    margin-top: 3.2rem;
}

.modelHeading {
    font-size: 1.15rem;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    color: #101010;
    margin-bottom: 1.5rem;
}

.rightMainBox {
    flex: 0.5;
    background: linear-gradient(97deg, #622A7A, #6F2F87, #5A2996, #4823A3, #4622A4, #1C105C);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media (max-width: 768px) {
    .rightMainBox {
        display: none
    }

    .mainContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}

.backgroundImage {
    width: 60%;
}

.backgroundStars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
}

.leftMainBox {
    flex: 0.5;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.disclaimer>p {
    margin-bottom: 1rem;
    font-size: .688rem;
}

.logoImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
}

.bulbImage {
    width: 2.25rem;
    height: 3rem;
}

.tagline {
    height: 19.21px;
}

.mainBox {
    width: max-content;
    font-size: 1rem;
    background-color: white;
    border-radius: 24px;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24rem;
    gap: 1rem;
}

.heading {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.heading h2 {
    text-align: center;
    margin: 0;
    color: var(--heading-dark-color);
    font-size: 1.75rem;
    font-weight: var(--font-weight-bold);
}

.schoolName {
    padding: .25rem .5rem;
    background: rgb(252, 220, 198);
    font-size: .75rem;
    border-radius: 2.5rem;
    font-weight: var(--font-weight-semiBold);
    color: #000;
    text-align: center;
    margin: .2rem 0;
    width: fit-content;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: .25rem;
    position: relative;
    padding-left: 1.75rem;
}

.schoolName img {
    margin-top: -.15rem;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 5.5px;
    left: .5rem;
}

.heading p {
    text-align: center;
    margin-top: .8rem;
    color: gray;
    font-size: 0.75rem;
    font-weight: var(--font-weight-regular);
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.nameInputsWrapper {
    display: flex;
    gap: 1rem;
}

.passwordStrengthBarContainer {
    width: 100%;
}

.actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.signIn {
    text-align: center;
}

.eula {
    display: flex;
    justify-items: center;
}

.eulaContainer {
    height: 75vh;
    width: 75vw;
}

.modelDialog :global(.MuiDialog-paperWidthSm) {
    max-width: 90vw;
}

.eulaContainer>div {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.OTPMessage {
    font-size: 0.75rem;
    font-weight: var(--font-weight-regular);
}

.eulaContainer iframe {
    background: white;
    height: 100%;
    width: 100%;
}

.checkbox {
    width: 16px;
    margin-right: 6px;
}

.OTPAndMessageWrapper {
    display: grid;
    gap: 1rem;
}

.OTPInputWrapper {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.OTPInput {
    width: 3rem;
    height: 3rem;
    background: var(--input-background-color);
    border-radius: 50%;
    border: none;
    text-align: center;
    outline: none;
}

.activeOTPInput {
    width: 3rem;
    height: 3rem;
    background: var(--heading-dark-color);
    border-radius: 50%;
    border: none;
    text-align: center;
    outline: none;
    color: white;
}


.OTPInput:focus {
    background-color: var(--heading-dark-color);
    color: white;
}

.resendButton {
    text-align: center;
    font-size: 0.75rem;
    font-weight: var(--font-weight-semiBold);
    margin-bottom: 0.5rem;
}

.numberFieldWrapper {
    width: 100%;
    background: var(--input-background-color);
    border-radius: 24px;
    display: flex;
    align-items: center;
}

.countryDropDown {
    width: 25%;
    padding-left: 0.75rem;
}

.divider {
    margin: 0 0.5rem;
    height: 19px;
    background-color: #DAD9D9;
    border: 1px solid #DAD9D9;
}

.getOTPButton {
    width: 26%;
    color: var(--button-color-yellow);
    font-size: 0.75rem;
    font-weight: var(--font-weight-bold);
    background: none;
    outline: none;
    border: none;
    padding-right: 0.75rem;
}

.errorMsg {
    color: red;
    margin-bottom: 0;
    font-size: 0.75rem;
    font-weight: var(--font-weight-medium);
}

.signIn {
    text-align: center;
    font-size: 0.75rem;
    font-weight: var(--font-weight-semiBold);
}