.backCoverAuthorImg {
    position: sticky;
    bottom: 2.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    background-color: #fff;
    height: fit-content !important;
}