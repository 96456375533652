.main {
    border: 1px solid #D3D3D3;
    border-radius: 0.5rem;
    transition: height 0.3s ease;
}

.header {
    border-radius: 0.5rem;
    background: rgb(225, 214, 231);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.title {
    color: black;
    font-family: var(--roboto);
    font-size: 1rem;
    font-weight: 500;
}

.icon {
    height: 1.625rem;
    width: 1.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    transition: transform ease-in-out 0.5s;
}

.active {
    transform: rotate(180deg);
}

.body {
    max-height: 0;
    padding: 0 1rem;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.bodyShow {
    max-height: fit-content; /* Set a maximum height that is high enough for your content */
    padding: 1rem;
}

.collapsing {
    max-height: 500px; /* Set a maximum height that is high enough for your content */
    padding: 1rem;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

/* Other styles remain unchanged */
 