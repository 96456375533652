.main {
  height: 100%;
  display: flex;
  align-items: center;
}

.main:focus-visible {
  outline: none;
}

.switch {
  transform: scale(0.8);
  padding: 0 0.5rem;
}