.main {
    background-color: rgb(274, 244, 249);
}

.orangeMain {
    background-color: rgb(254, 248, 243);
}

.heading {
    height: 4.10rem;
    background-color: rgb(225, 214, 231);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 2.25rem;
}

@media (max-width: 768px) {
    .heading {
        justify-content: flex-start;
    }

}

.orangeHeading {
    height: 4.10rem;
    background-color: rgb(252, 220, 198);
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading p {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    color: var(--app-dark);
}

.orangeHeading p {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    color: rgb(242, 110, 20)
}

.booksContainer {
    padding: min(4rem, 5vw) min(4.5rem, 5vw);
}

.booksContainer::-webkit-scrollbar {
    display: none;
}

.slider {
    position: relative;
    width: 80%;
    /* Adjust width as needed */
    margin: 0 auto;
    overflow: hidden;
}

.sliderWrapper {
    display: flex;
    transition: transform 0.5s ease;
}

.slide {
    flex: 0 0 auto;
    width: 100%;
    /* Adjust width as needed */
}

.prevBtn,
.nextBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
}

.prev-btn {
    left: 0;
}

.next-btn {
    right: 0;
}

.searchWrapper {
    position: absolute;
    right: 1rem;
}

.searchBoxWrapper {
    background-color: #fff;
}