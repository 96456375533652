
.proofreadingContainer {
    margin-top: 1.5rem;
  }
  
  .proofreadingContainer > p {
    margin-bottom: 0.5rem;
  }
  
  .proofreadingGrid {
    display: grid;
    gap: 1rem;
  }
  
  .subGrid {
    display: grid;
    grid-template-columns: subgrid;
    gap: 1rem;
    height: -moz-fit-content;
    height: fit-content;
    grid-column: span 3;
  }
  
  .subGrid > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .modalBody  {
    margin: 1.5rem 0;
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .skipBtn {
    font-size: .875rem;
    margin: 0;
    height: 2rem;
    width: max-content;
    padding: .5rem;
  }