.main {
    width: 85%;
    box-sizing: border-box;
    height: 100%;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: min(3.125rem, 5vw) min(6.25rem, 5vw);
    border-radius: 8px;
    background-color: #fff;
}

.headingWrapper {
    width: 100%;
}

.heading {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    color: var(--heading-dark-color);
    margin: 0;
}