.button {
    background: white;
    padding: 0.375rem;
    border-radius: 0.75rem;
    border: solid #0004 2px;
    width: 2rem;
    height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.button.disabled {
    opacity: 0.3 !important;
    pointer-events: none;
}

.button>img {
    height: -webkit-fill-available;
}