.sidebarController {
    display: block;
    z-index: 5;
}

.close {
    position: absolute;
    height: calc(100% - 3.2rem);
    width: 20rem;
    background-color: var(--header-color);
    transform: translateX(-100%);
    transition: 450ms;
    margin-left: -2rem;
    padding-left: 2rem;
}

.open {
    position: absolute;
    height: calc(100% - 3.2rem);
    width: 29rem;
    background-color: var(--header-color);
    transform: translateX(0%);
    transition: 450ms;
    margin-left: -2rem;
    padding-left: 2rem;
}

.logoContainer {
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.logoImage {
    cursor: pointer;
    width: 1.75rem;
}

.navMenu {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins';
    font-size: 0.875rem;
    row-gap: 1.25rem;
}

.navMenuItem {
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    color: rgb(76, 38, 100);
    cursor: pointer;
    border-bottom: 1px solid transparent;
    margin-top: 1rem;
}

.navMenuItemActive {
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    color: #DB6412;
    cursor: pointer;
}

.icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem;
}

.barsIcon {
    font-size: 1.5rem;
}

.cancelIcon {
    font-size: 1.875rem;
}

.sidebarIcon {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: -0.5rem;
}