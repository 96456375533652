.main {
    min-height: 16.65rem;
    max-height: max-content;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
}

.imageWrapper {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    background-color: rgb(245, 245, 245);
}

.deleteIcon {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    color: var(--selection-dark);
    background-color: white;
    font-size: 0.85rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
    right: 1rem;
}

.bookImage {
    width: 6rem;
    height: 9rem;
    border-radius: 8px;
}

.bookDetailsWrapper {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}

.bookName {
    margin: 0;
    font-size: 1rem;
    text-align: center;
    font-weight: var(--font-weight-semiBold);
    text-transform: capitalize;
}

.authorName {
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    text-transform: capitalize;
}


.ratingsLikesViews {
    display: flex;
    gap: 1rem;
}

.ratingsLikesViewsTitles {
    font-size: 0.75rem;
    text-align: center;
    opacity: 0.8;
}

.ratingsLikesViewsContent {
    font-size: 0.75rem;
    text-align: center;
}

.starImage {
    margin-left: 0.2rem;
    color: var(--selected-theme-color);
}

.heartImage {
    margin-left: 0.2rem;
    color: red;
}

.eyeImage {
    margin-left: 0.2rem;
}