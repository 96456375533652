.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.textInputWrapper {
  width: 100%;
}

.startButton {
  padding: 0.5rem;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
}

.input {
  border-radius: 0px;
  border: 1px solid;
}

.buttonsWrapper {
  display: flex;
  gap: 0.5rem;
}

.error {
  color: #ce0c0c;
  font-size: 1rem;
  font-weight: 500;
}

.recordingButton {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: #ffe6bf;
  }

  100% {
    background-color: transparent;
  }
}