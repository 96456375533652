.searchBar {
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0.25rem 0.75rem;
    font-size: 1.2rem;
    background-color: var(--app-white);
}

.searchBar input {
    border: none;
    width: 80%;
    font-size: 0.875rem;
    height: 100%;
    padding-left: 0.5rem;
}

.greyBackground {
    background-color: var(--app-semi-light-grey);
}

.greyBackground input {
    background-color: var(--app-semi-light-grey);
}

.icon {
    font-size: 1rem;
    margin-left: 0.25rem;
    cursor: pointer;
    color: var(--app-grey);
}

textarea:focus,
input:focus {
    outline: none;
}