.main {
    display: grid;
}

.inputFelid {
    height: 2rem;
    padding-left: 1.25rem;
    font-size: 0.875rem;
    font-family: 'Poppins';
    background: #FAFAFA;
    border: 1px solid #F1F0F0;
    border-radius: 4px;
    outline: none;
}

.inputFelid:disabled {
    cursor: not-allowed;
}

.inputFelid:focus {
    border: 1px solid #FB7E05;
    background: #FFF3DF;
}
.userDetailsLabel {
    font-size: 0.875rem;
}