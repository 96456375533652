.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.75);
    border-radius: inherit;
}

.modal {
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.closeIconWrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    background-color: white;
    color: var(--selection-dark);
    align-content: center;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
}

.container {
    position: relative;
    align-self: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    object-fit: contain;
    width: 95%;
    height: 95%;
}