.mainContainer {
    width: 100%;
    font-family: Poppins;
}

.detailsBox {
    display: flex;
    /* flex-direction: row; */
    background-color: rgb(28, 16, 92);
    color: white;
    padding: 4rem;
    flex-wrap: wrap;
    gap: 4rem;
}

.productBox {
    flex: 0.3;
    display: flex;
    justify-content: flex-start;
    margin: auto;
}

.productBoxLogoAndContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
}

.productBox h2 {
    font-size: 2.5rem;
}

.productBox p {
    font-size: 1rem;
}

.socialBox {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.socialBox>ul {
    display: flex;
    gap: 1rem;
}

.socialBox li {
    display: inline;
}

.socialBox li>img {
    width: 2.25rem;
    height: 2.25rem;
}

.exploreBox {
    flex: 0.23;
    min-width: 8rem;
    margin-right: 2rem;
}

.quickLinksBox {
    flex: 0.23;
    min-width: 8rem;
    margin-right: 2rem;
}

.contactUsBox {
    flex: 0.23;
    min-width: 8rem;
}

.exploreBox li,
.quickLinksBox li,
.contactUsBox li {
    margin-top: 1rem;
    font-size: 1rem;
}

.exploreBox h3,
.quickLinksBox h3,
.contactUsBox h3 {
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
}

ul {
    list-style-type: none;
    padding-inline-start: 0;
}

.bookAloozaLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
}

.logo {
    /* width: 4rem; */
    height: 8rem;
}

.tagline {
    width: 15rem;
    margin-bottom: 3rem;
}

.phone,
.mail,
.address {
    color: rgb(255, 213, 54);
    margin-top: 0.5rem;
}

.phone {
    text-decoration: none;
}

.mail a {
    color: rgb(255, 213, 54);
    text-decoration: none;
}

.exploreBoxItem {
    cursor: pointer;
}

.footerLinks {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
}

@media screen and (min-width: 1000px) {
    .socialBox>ul {
        display: flex;
        gap: 1rem;
    }

    .productBoxLogoAndContent {
        margin-left: 5rem;
    }
}


@media screen and (min-width: 850px) and (max-width: 1500px) {
    .socialBox>ul {
        display: flex;
        gap: 1rem;
    }
}

@media screen and (min-width: 481px) and (max-width: 850px) {
    .socialBox>ul {
        display: flex;
    }

    .mail {
        word-wrap: break-word;
    }


}

@media (max-width: 480px) {
    .socialBox>ul {
        display: flex;
    }

    .mail {
        word-wrap: break-word;
    }

    .footerLinks {
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }

    .detailsBox {
        padding: 5rem;
    }

    .quickLinksBox {
        margin-top: 1rem;
    }

    .contactUsBox {
        margin-top: 1rem;
    }
}