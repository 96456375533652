.colorName {
    color: var(--text-light-color);
    display: inline-block;
    margin-left: 1rem;
}

.listView {
    display: flex;
    align-items: center;
    margin-bottom: 0.4rem;
}

.gridView {
    display: inline-block;
}

.listSelected {
    background-color: rgb(214 156 28);
}