.main {
    width: 17rem;
    box-sizing: border-box;
    height: 15.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 1.5rem;
    background-color: white;
    border-radius: 15px;
    padding: 3rem 2rem;
    color: rgb(25, 104, 255);
    transition: height 0.3s, margin-bottom 0.3s, transform 0.3s;
}

.main:hover {
    height: 17.5rem;
    transform: translateY(-2rem);
    background: linear-gradient(224deg, rgba(85, 191, 198, 1), rgba(5, 109, 183, 1), rgba(2, 71, 153, 1));
    color: white;
    margin-bottom: -2rem;
}

.thumbsUp {
    display: none;
}

.main:hover .thumbsUp {
    display: block;
}

@media screen and (min-width: 821px) and (max-width: 1300px) {
    .main {
        width: 12rem;
        font-size: 1.125rem;
        font-weight: 500;
    }
}


@media screen and (min-width: 481px) and (max-width: 820px) {
    .main {
        width: 12rem;
        font-size: 1.125rem;
        font-weight: 500;
    }
}

@media screen and (min-width: 360px) and (max-width: 480px) {
    .main {
        width: 11rem;
        height: 12rem;
        font-size: 1.125rem;
        font-weight: 500;
    }

    .main:hover {
        width: 11rem;
        height: 13rem;
        padding-top: 1rem;
        transform: translateY(-1.5rem);
    }

    .main:hover>div {
        display: flex;
        align-items: center;
        height: 2rem;
        margin-top: -1rem;
    }
}