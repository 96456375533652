.main {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/signin-signup-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mainBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  font-size: 1rem;
  background-color: var(--panel-semi-dark-background);
  border-radius: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
  padding: 2rem 2rem 4rem 2rem;
}

.logoImage {
  display: grid;
  justify-items: center;
  margin-bottom: 1.5rem;
}

.bulbImage {
  width: 2.224rem;
  height: 3.037rem;
}

.tagline {
  width: 7.5rem;
}

.heading h2 {
  text-align: center;
  margin: 0;
  color: var(--heading-dark-color);
  font-size: 1.75rem;
  font-weight: var(--font-weight-bold);
}

.heading p {
  text-align: center;
  margin: 0;
  color: gray;
  font-size: 0.75rem;
  font-weight: var(--font-weight-regular);
}

.header {
  width: 100%;
  margin-bottom: 2rem;
}

.title {
  color: #5e17eb;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
}

.subTitle {
  color: var(--button-color-orange);
  font-size: 0.875rem;
  width: 100%;
  text-align: center;
}

.nameInputsWrapper {
  display: flex;
  gap: 1rem;
}

.errorMsg {
  color: red;
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: var(--font-weight-medium);
}

.divider {
  margin: 0 0.5rem;
  height: 19px;
  background-color: #dad9d9;
  border: 1px solid #dad9d9;
}

.numberFieldWrapper {
  width: 100%;
  background: var(--input-background-color);
  border-radius: 24px;
  display: flex;
  align-items: center;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}


.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 0.75rem;
  margin-top: 1.5rem;
}