.mainContainer {
    display: grid;
    gap: 1rem;
}

.heading {
    display: flex;
}

.innerContainer {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 1.2rem;
}

.publishedBooksWrapper {
    border: 1px solid rgba(236, 236, 236, 1);
    border-radius: 8px;
    display: grid;
}

.inReviewsBooksWrapper {
    border: 1px solid rgba(236, 236, 236, 1);
    border-radius: 8px;
    display: grid;
}

.draftBooksWrapper {
    border: 1px solid rgba(236, 236, 236, 1);
    border-radius: 8px;
    display: grid;
}

.publishedBooksTitle {
    width: 100%;
    box-sizing: border-box;
    background: #6A3089;
    padding: 0.5rem 0 0.5rem 2rem;
    color: white;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
}

.inReviewsBooksTitle {
    width: 100%;
    box-sizing: border-box;
    background: var(--heading-dark-color);
    padding: 0.5rem 0 0.5rem 2rem;
    color: white;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
}

.draftBooksTitle {
    width: 100%;
    box-sizing: border-box;
    background: rgb(163, 161, 161);
    padding: 0.5rem 0 0.5rem 2rem;
    color: white;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
}

.headerText {
    width: 100%;
    color: var(--heading-dark-color);
    font-size: 2rem;
    text-align: center;
}


.addNew {
    margin-right: 1rem;
}

.bookList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(16rem, 20vw), 1fr));
    padding: 2rem;
    gap: 2rem;
    max-height: 32rem;
    overflow: auto;
}

.noBooks {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.noBooks div {
    margin-top: 1rem;
}

.noBooks span {
    color: var(--btn-primary-color);
}

.buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.emptyMessageWrapper {
    text-align: center;
    margin: 2rem 0;
}