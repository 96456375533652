.main {
    margin-top: 3.2rem;
    width: 100%;
    background: #fff;
}

.heroImageContainer {
    width: 100%;
    position: relative;
}

.heroImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.heroButton {
    position: absolute;
    top: 21vw;
    left: 24vw;
}

.heroBtn {
    height: unset;
    padding: .5rem .75rem;
    font-size: 2.2vw;
    color: #5b0bfa;
    background: #fff;
    border-color: #5b0bfa;
}

.heroBtn:hover {
    background: #5b0bfa;
    color: #fff;
}

.testimonialsContainer {
    margin: 0 auto;
    max-width: 80rem;
    min-height: max-content;
    padding: 3rem 1.5rem;
}

.gridContainer {
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(auto-fill, minmax(364px, 1fr));
    height: 100%;
    justify-content: center;
    min-height: fit-content;
    width: 100%;
}

.video {
    border: 1px solid #d9e3ea;
    border-radius: 1rem;
    grid-row-end: span 150;
    margin: 0.5rem;
    max-height: max-content;
    opacity: 1;
    overflow: hidden;
    position: relative;
}

.videoImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.videoOverlayIcon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videoImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.videoOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.3s ease;
    border-bottom-left-radius: var(--video-border-radius) !important;
    border-bottom-right-radius: var(--video-border-radius) !important;
}

.videoOverlayContent {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 800;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(239, 68, 68, 0));
}

.videoBookName {
    font-size: .875rem;
    font-weight: 600;
    font-style: italic;
}

.videoOverlayRating  {
    flex: 1;
    min-width: fit-content;
    max-width: max-content;
}

.testimonialCard {
    grid-row-end: span 184;
    opacity: 1;
    padding: 0.5rem;
}

.testimonialCardContent {
    padding: 1rem;
    border: 1px solid rgb(217 227 234);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    /* height: 100%;
    box-sizing: border-box; */
}

.readMoreButton {
    font-size: 1rem;
    width: max-content;
    border: none;
    outline: none;
    background: none;
    margin: 0;
    padding: 0;
    margin-bottom: 0.75rem;
    text-decoration: underline;
}

.testimonialCardHeader {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
}

.testimonialCardHeader span {
    font-size: 1rem;
    font-weight: 700;
}

.testimonialCardRatings {
    margin: 0.75rem 0;
    flex: 1;
    min-width: fit-content;
    max-width: max-content;
}

.testimonialCardText {
    /* flex-grow: 1; */
    /* overflow-wrap: break-word; */
    font-size: 1rem;
}

.description {
    color: #4a4a4a;
    margin: 0;
    overflow: hidden;
    margin-bottom: 0.75rem;
}

.showMore {
    background: none;
    border: none;
    color: #666;
    padding: 8px 0;
    cursor: pointer;
    font-size: 0.875rem;
}

.showMore:hover {
    text-decoration: underline;
}

.testimonialCardFooter {
    margin-top: 0.875rem;
}

@media (max-width: 768px) {
    .heroButton {
        top: 21vw;
        left: 23vw;
    }
}