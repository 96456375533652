.animatedList {
    margin-block: .25em;
    margin-inline-start: .25em;
}

.animatedList.inView .listItem {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.listItem {
    opacity: 0;
    color: black;
    padding: .25rem;
    transform: translateX(-100px);
}