.main {
    position: relative;
    width: fit-content;
    border-radius: 4rem;
    padding: .2rem;
    background: transparent;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: .5rem;
    background-color: #fff;
    background-color: #569ead;
    color: #fff;
    overflow: hidden;
}

.handle {
    position: absolute;
    box-sizing: border-box;
    height: 2.5rem;
    border-radius: 5rem;
    background: #fff;
    border: 1px solid #569ead;
    transition: 0.5s;
    left: 0;
    z-index: 0;
}

.opt {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    transition: 0.5s;
    cursor: pointer;
    z-index: 1;
    padding: max(.13rem, 1vw);
    color: inherit;
    font-size: max(0.75rem, 1.5vw);
    line-height: max(1.25rem, 2vw);
    letter-spacing: -0.00094rem;
}

.opt.activated {
    color: #569ead;
    cursor: auto;
}

.hide {
    display: none;
}