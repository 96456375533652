.scrollToTop{
        position: relative;
        cursor: pointer;
}

.iconPosition{
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
    width: 3rem;
    height: 3rem;
      transition: all .5s ease-in-out;
  }
