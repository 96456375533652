.input {
  width: 100%;
  background-color: var(--input-background-color);
  color: black;
  border: none;
  border-radius: 25px;
  padding-left: 1rem;
  height: 2.25rem;
  width: 100%;
  box-sizing: border-box;
}

.input::placeholder {
  color: black;
}

.input:focus {
  outline: none;
}

.OTPInput {
  width: 3rem;
  height: 3rem;
  background: var(--input-background-color);
  border-radius: 50%;
  border: none;
  text-align: center;
  outline: none;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
}

.OTPInput::-webkit-inner-spin-button,
.OTPInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.OTPInput[value]:not([value=""]),
.OTPInput:focus {
  width: 3rem;
  height: 3rem;
  background: var(--heading-dark-color);
  border-radius: 50%;
  border: none;
  text-align: center;
  outline: none;
  color: white;
}
