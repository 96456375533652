.mainContainer {
    display: flex;
    flex-direction: column;
}

.addReviewTitleWrapper {
    flex: 0.25;
}

.commentBoxWrapper {
    flex: 0.75;
}

.addReview {
    margin-bottom: 1.157rem;
    font-size: 1rem;
    font-weight: var(--font-weight-regular);
}

.commentBox {
    display: block;
    border-radius: 6px;
    border: 2px solid rgb(173, 141, 190);
    width: 100%;
    height: 10rem;
    box-sizing: border-box;
    outline: none;
    padding: 1.5rem 2rem;
    font-family: 'Poppins';
    font-size: 0.875rem;
    resize: none;
}

.submitReview {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.reviewStatus {
    margin-top: 0.5rem;
    width: 100%;
}

.action {
    width: 10rem;
    margin-top: 1rem;
}

@media screen and (min-width: 360px) and (max-width: 480px) {
    .mainContainer {
        gap: 1rem;
        justify-content: space-between;
    }

    .commentBox {
        width: 100%;
        padding: 0.5rem 0.5rem;
    }

    .addReviewTitleWrapper {
        flex: 0.7;
    }

    .commentBoxWrapper {
        flex: 1;
        display: flex;
        justify-content: end;
    }
}