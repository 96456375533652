.main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 3.2rem;
    padding-bottom: 3rem;
    background-color: var(--background-color);
}

.banner {
    width: 100%;
    height: 100%;
    position: relative;
}

.btnWrapper {
    position: absolute;
    left: 3vw;
    bottom: 27vw;
}

.btnWrapper button {
    height: auto;
    border-radius: 5rem;
    padding: min(2vw, 1.5rem);
    font-size: max(0.75rem, 1.5vw);
    border: none;
    background: #569ead;
    transition: all ease 0.5s;
}

.btnWrapper button:hover,
.btnWrapper button:focus,
.createMobileButton button:hover,
.createMobileButton button:focus {
    background: #3c8f9f !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.tabsWrapper {
    position: absolute;
    bottom: 5vw;
    left: 3vw;
}

.banner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.backButtonWrapper {
    width: 85%;
}

.podcastsWrapper {
    display: grid;
    grid-template-columns: auto;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.podcastsWrapper:has(.errorWrapper) {
    grid-template-columns: auto;
}

.errorWrapper {
    margin-top: 1.5rem;
    width: 100%;
}

.errorMsg {
    display: block;
    margin: auto;
    width: max-content;
    color: var(--app-danger);
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.pageItem {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid #E8E9EC;
    border-radius: 4px;
    text-align: center;
    color: #4D4F5C;
    display: flex;
    align-items: center;
    justify-content: center;
}

.break {
    width: 2em;
    height: 2rem;
    padding: 0.5rem;
    display: flex;
    align-items: end;
    justify-content: center;
}

.pageItemPrevious {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid #E8E9EC;
    border-radius: 4px;
    text-align: center;
    color: #E8B03E;
    display: flex;
    align-items: center;
    justify-content: center;
}


.pageItemActive {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border: 1px solid var(--button-color-orange);
    border-radius: 4px;
    text-align: center;
    color: white;
    display: flex;
    background: var(--button-color-orange);
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.pageLink {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageLink[aria-disabled="true"] {
    pointer-events: none;
    opacity: 0.4;
}

@media (min-width: 768px) {
    .podcastsWrapper {
        grid-template-columns: auto;
    }
}

@media (min-width: 992px) {
    .podcastsWrapper {
        grid-template-columns: repeat(auto-fill, minmax(21.5rem, 1fr));
    }
}