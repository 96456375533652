.main {
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    padding: 2rem;
    border-radius: 6px;
}

.headingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading {
    margin: 0;
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
}

.headingWrapper{
    display: flex;
    gap: 0.75rem;
}

.chevronIconWrapper {
    border: 1px solid rgba(0, 0, 0, 0.65);
    color: rgba(0, 0, 0, 0.65);
    border-radius: 50%;
    width: 1.5rem;
    box-sizing: border-box;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    padding: 0.3rem;
}

.authorDiscountWrapper {
    background: #FFF6F0;
    border: 1px solid #FFFEEA;
    display: flex;
    padding: 0.75rem 0;
    align-items: center;
}

.authorDiscountHeading {
    font-size: 0.875rem;
    box-sizing: border-box;
    padding-left: 1rem;
    font-weight: var(--font-weight-medium);
    width: 50%;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.authorDiscount {
    font-size: 0.875rem;
}

.quantityDiscountTableWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.quantityDiscountTableLabel {
    font-size: 0.875rem;
    font-weight: var(--font-weight-medium);
}

.quantityDiscountTable {
    width: 100%;
}

.tableHeadingCell {
    text-align: left;
    font-weight: var(--font-weight-regular);
    color: rgba(112, 112, 112, 1);
    font-size: 0.875rem;
}

.tableHeadingCell:first-child {
    text-align: left;
    padding-left: 1rem;
}

.tableRowWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.tableBodyRow {
    display: contents;
}

.tableBodyCell {
    padding-left: 1rem;
    font-weight: var(--font-weight-regular);
    height: 2.5rem;
    display: flex;
    align-items: center;
}

.tableCell {
    font-weight: var(--font-weight-regular);
}

.mostRecommendedLabel {
    position: relative;
    font-size: 0.75rem;
    left: 0.5rem;
    display: flex;
    padding: 0 0.5rem;
    width: -moz-fit-content;
    font-weight: var(--font-weight-medium);
    width: fit-content;
    color: var(--selection-dark);
    background-color: white;
    margin-bottom: -1rem;
    top: -0.4rem;
    z-index: 10;
}

.mostRecommended {
    position: relative;
    border-radius: 4px;
    border: 1px solid rgba(163, 161, 161, 1);

}

.mostRecommendedCell {
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: var(--font-weight-medium);
}

.mostRecommendedCell:first-child {
    border-right: none;
}

.mostRecommendedCell:last-child {
    border-left: none;
}

.disclaimer {
    background-color: #FBF5FF;
    border-radius: 8px;
    padding: 0.75rem;
    font-size: 0.75rem;
    font-weight: var(--font-weight-semiBold);
}

.boldText {
    font-weight: var(--font-weight-bold);
}

.quantityDiscountTableHeading {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom:  1px solid rgba(0, 0, 0, 0.3);
}