.starAuthors {
    width: 100%;
    box-sizing: border-box;
    background: rgb(102, 45, 145);
    background: linear-gradient(0deg, rgba(102, 45, 145, 1) 55%, rgba(242, 101, 34, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    flex-wrap: wrap;
    padding: min(5rem, 8vw);
    z-index: 10;

    overflow: hidden;
}

.sectionHeading {
    width: 100%;
    text-align: center;
    font-size: 2.375rem;
    font-weight: var(--font-weight-bold);
    color: white;
}

.carousel button {
    color: #fff;
}

.carousel button:disabled {
    color: #9a9a9a;
}


@media (max-width: 480px) {
    .sectionHeading {
        font-size: 1.375rem;
    }
}