.main {
    display: grid;
    gap: 2.5rem;
}

.container {
    display: grid;
    gap: 2rem;
}

.addBankDetails {
    display: grid;
    gap: 1.25rem;
    padding: 2.25rem;
    border: 1px solid var(--selection-dark); 
    border-radius: 8px;
}

.checkBoxAndButtonsWrapper {
    display: grid;
    gap: 2rem
}

.checkBoxWrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

/* .checkBox:checked {
    --form-control-color: black;
    color: black;
    height: 1.5rem;
    width: 1.5rem;
} */

.checkBox[type=checkbox] {
    position: relative;
    width: 1.5rem;
    height: 1.85rem;
}

.checkBox[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    left: 0;
    border: 1px solid gray;
    border-radius: 3px;
    background-color: white;
}

.checkBox[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 0.5rem;
    height: 0.95rem;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 15%;
    left: 33%;
}

.buttonsWrapper {
    display: flex;
    gap: 2rem;
}

.bankDetails{
    background: #F5F5F5;
    padding: 1.625rem 2.875rem;
    border-radius: 8px;
    display: grid;
    gap: 1rem;
}

.fieldWrapper {
    display: flex;
    width: 80%;
}

.detailsLabel {
    width: 30%;
    opacity: 50%;
}

.detailsContent{
    width: 70%;
}

.addBankDetails .success {
    color: green;
  }
  
.addBankDetails .error {
    color: red;
}