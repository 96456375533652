.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container {
    display: grid;
    gap: 1rem;
}

.cards {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem 2rem;
    background-color: rgb(244, 224, 255);
    border-radius: 8px;
}

.cardsDark {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem 2rem;
    background-color: rgb(232, 228, 251);
    border-radius: 8px;
}
.cardIconWrapper {
    width: 4.25rem;
    height: 4.25rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.cardIcon {
    width: 2.75rem;
    height: 2.75rem;
}

.writingBookCard {
    height: 5rem;
    display: flex;
}

.forwardButtonIcon {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: rgb(242, 110, 20);
    color: white;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forwardButtonIconBig {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: rgb(242, 110, 20);
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardHeading {
    font-size: 0.875rem;
    font-weight: var(--font-weight-bold);
    color: var(--selection-dark);
    margin: 0;
}

.cardContentWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 0.75rem;
    font-weight: var(--font-weight-medium);
}

.earningsCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2.5rem;
    background: var(--selection-dark);
    color: white;
    border-radius: 8px;
    margin-top: 1.12rem;
}

.earningHeading {
    margin: 0;
    font-size: 1rem;
    font-weight: var(--font-weight-semiBold);
}

.earningPrice {
    font-size: 1rem;
    font-weight: var(--font-weight-regular); 
}