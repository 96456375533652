.main {
    display: flex;
    height: fit-content;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
    margin-top: 3.2rem;
    background-color: var(--background-color);
}


.sidebarAndContainerWrapper {
    width: 85%;
    height: fit-content;
    display: flex;
    background: white;
    border-radius: 8px;
}

.left {
    width: 20rem;
    background-color: rgb(252, 220, 198);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-direction: column;
    padding: 2rem 0;
}

.userDetailsWrapper {
    width: 70%;
    height: fit-content;
    display: grid;
    gap: 1.5rem;
}

.myAccountHeading {
    font-size: 1.25rem;
    font-weight: var(--font-weight-regular);
    margin: 0;
}

.userImageAndNameWrapper {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.userImage {
    width: 2.2rem;
    height: 2.2rem;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid transparent;
    outline: 1px solid black;
}

.userName {
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
}

.navigation {
    width: 100%;
}

.navItem {
    height: 3rem;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semiBold);
    display: flex;
    align-items: center;
    padding-left: 2rem;
    gap: 1rem;
}

.navItems {
    display: grid;
    gap: 1rem;
}

.navItemContentWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.navItem:hover svg {
    stroke: white;
}

.navItem:hover svg path {
    stroke: white;
}

.navItem.active svg {
    stroke: white;
}

.navItem.active svg path {
    stroke: white;
}

.navItemContentWrapper img {
    margin-right: 1rem;
}

.navItem.active,
.navItem:hover {
    background: #FF9852 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    color: white;
}

.right {
    width: 80%;
    padding: 3rem 3.25rem;
}

.horizontalNavbar {
    display: none;
}

@media (max-width: 600px) {
    .left {
        display: none;
    }

    .navTitle {
        display: none;
    }

    .horizontalNavbar {
        display: flex;
        justify-content: space-around;
        width: 85%;
        background-color: rgb(252, 220, 198);
        border-radius: 0.5rem;
    }

    .navItem {
        padding-left: 0rem;
        padding: 0.5rem;
        height: auto;
        justify-content: center;
        border-radius: 0.25rem;
    }

    .navItemContentWrapper {
        justify-content: center;
        width: auto;
        height: auto;
        margin-left: 0rem;
    }

    .navItemContentWrapper>img {
        margin-right: 0rem;
        width: 100%;
    }

    .navItem.active,
    .navItem:hover {
        padding: 0.5rem;
        justify-content: center;
    }

    .right {
        padding: 3rem 2.875rem;
    }

    .main {
        padding: 2rem 0;
    }

    .sidebarAndContainerWrapper {
        margin-top: 1rem;
    }
}