.toolpanel-button {
	color: var(--text-light-color);
	text-align: center;
	font-family: 'FontAwesome';
	font-size: 1.5rem !important;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
}

.x-btn-disabled {
	opacity: 0.3;
	pointer-events: none;
}

.x-btn-pressed {
	background-color: var(--selection-light);
	color: var(--selection-dark);
}

.toolpanel-button svg {
	width: 1.5rem;
	height: 1.5rem;
}

.x-btn-pressed path {
	fill: var(--selection-dark)
}