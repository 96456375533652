.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
    align-items: center;
    margin-top: 3.2rem;
}

.paddedContainer {
    width: 85%;
}

.themesContainerWrapper {
    height: 100%;
}

.innerContainer {
    display: grid;
    gap: 3rem;
    margin-top: 2rem;
}

.heading {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerText {
    width: 100%;
    align-self: flex-end;
    font-size: 2rem;
    text-align: center;
    color: var(--heading-dark-color);
}

.sectionHeading {
    font-size: 1.5rem;
    font-weight: var(--font-weight-semiBold);
    margin-bottom: 1rem;
    text-align: center;
}

.chooseTheme {
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.themesWrapper {
    border-radius: 1.5rem;
    padding: min(2.5rem, 4vw);
    color: white;
    box-sizing: border-box;
}

.trendingThemes {
    background: linear-gradient(180deg, rgb(122, 25, 196), rgb(70, 14, 112), rgb(67, 14, 107));
}

.mostPopularThemes {
    background: linear-gradient(180deg, rgb(77, 34, 196), rgb(35, 19, 117));
}

.allThemes {
    background: rgb(242, 110, 20);
    position: relative;
}

.themes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    max-height: 34rem;
    padding: 1rem 0;
}

.container {
    border-radius: 25px;
    background: white;
    margin-top: 1.5rem;
    box-sizing: border-box;
}

.allThemesContainer {
    padding: 1.25rem;
}

.nextButton {
    border-radius: 1.5rem;
    text-align: right;
    padding: .75rem 1rem;
    box-shadow: 0px -2px 5px 0px rgb(224 224 224);
    display: flex;
    align-items: center;
    justify-content: end;
}

.scrollBarForThemes::-webkit-scrollbar {
    width: 5px;
}

.scrollBarForThemes::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #1C105C;
}


.scrollBarForThemes::-webkit-scrollbar-thumb {
    border-radius: 18px;
    background-color: #DB6412;
}

@media (min-width: 768px) {
    .scrollBarForThemes {
        height: 95%;
    }
}