.main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem 0rem;
    justify-content: center;
}


.container {
    width: 85%;
    box-sizing: border-box;
    max-height: fit-content;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background: white;
    border-radius: 8px;
    flex-wrap: wrap;
}


.leftSideContainer{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    background-color: #F1F1F1;
    padding: 2rem ;
}

.rightSideContainer{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}


.thankyouImage img{
    width: 10rem;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.thankyouTitle{
    color:rgb(63, 33, 123) ;
    font-size: 3rem;
}


@media screen  and  (min-width: 820px){
    .main{
        height: 100%;
        padding: 0rem;   
    }
    
    .container{
        flex-wrap: nowrap;
        margin: 2rem;
    }

    .rightSideContainer{
        padding: 3.125rem;
    }

    .leftSideContainer{
        padding: 3.125rem;
    }

    .thankyouImage img{
        width: 10rem;
    }
}
